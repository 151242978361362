import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { ToastService } from '@src/app/core/toast.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-deactivate-profile-dialog',
  templateUrl: './deactivate-profile-dialog.component.html',
})
export class DeactivateProfileDialogComponent {
  @ViewChild('modalRef') modalRef: ElementRef;
  @Input() heading: string;
  @Input() message: string;
  @Input() confirmLabel = 'Ok';
  @Input() cancelLabel = 'Cancel';
  @Input() subMessage: string;
  @Input() deactivateObs$: Observable<any>;
  @Input() successCallback: Function;

  isDeactivating = false;

  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private authenService: AuthenService
  ) {}

  ngOnInit(): void {}

  confirm(): void {
    this.isDeactivating = true;
    this.deactivateObs$.subscribe({
      next: () => {
        this.toastService.showSuccess('Deactivated successfully');

        this.successCallback();
      },
      error: () => {
        this.toastService.showError('Deactivated fail');
      },
      complete: () => {
        this.isDeactivating = false;
        this.activeModal.close(true);
      },
    });
  }

  cancel(): void {
    this.activeModal.close(false);
  }
}
