import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { ImageInterface } from '@shared/interfaces/file.interface';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { TenantHttpService } from '@src/app/core/http/tenant.http.service';
import { SessionService } from '@src/app/core/session.service';
import {
  AttributeType,
  SystemType,
} from '@src/app/shared/enums/attribute-type.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { LinkInterface } from '@src/app/shared/interfaces/attribute-description.interface';
import {
  TENANT_ATTRIBUTES_SECTION_FIELDSNAME,
  TENANT_DEFAULT_BANNER_PROPERTY,
  TENANT_DEFAULT_LOGO_PROPERTY,
  TENANT_EMAIL_CONFIGURATION_FIELDNAMES,
  TenantConfigurationSectionIndex,
  TenantInterface,
} from '@src/app/shared/interfaces/tenant.interface';
import { CustomMetadataUtils } from '@src/app/shared/utils/custom-metadata-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { Observable, of } from 'rxjs';
import { BaseEntityEditComponent } from '../../base/base-detail/base-entity-edit/base-entity-edit.component';

@Component({
  selector: 'app-tenant-configuration',
  templateUrl: './tenant-configuration.component.html',
})
export class TenantConfigurationComponent
  extends BaseEntityEditComponent<TenantInterface>
  implements OnInit
{
  TenantConfigurationSectionIndex = TenantConfigurationSectionIndex;
  AttributeType = AttributeType;
  SystemType = SystemType;
  windowOrigin = window.location.origin;

  notAllowUpdateFields: string[] = [
    'Id',
    'Domain',
    'DataSegregationModel',
    'DatabaseConnectionString',
  ];

  storedPassword: string;

  excludeLabelFields = ['SendEmail', 'EmailSMTPEnableTsl'];

  constructor(
    public tenantHttpService: TenantHttpService,
    protected authenService: AuthenService,
    protected sessionService: SessionService,
    protected injector: Injector
  ) {
    super(tenantHttpService, authenService, sessionService, injector);

    this.entityName = EntityName.Tenant;
  }

  ngOnInit(): void {
    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe((apiReady) => {
        if (apiReady) {
          const tenantId = Number(this.sessionService.getTenantId());

          if (!isNaN(tenantId) && tenantId > 0) {
            this.id = tenantId;
            this.formDataService.entityId = tenantId;
            this.loadEntity();
          }
        }
      });
  }

  customInit(): void {
    this.entityRoot = environment.jipUrl.adminSettings;
    this.formSections = [
      {
        fieldsName: TENANT_ATTRIBUTES_SECTION_FIELDSNAME,
      },
      {
        fieldsName: TENANT_EMAIL_CONFIGURATION_FIELDNAMES,
      },
    ];
  }

  getFormFieldValueFromEntity(name: string): any {
    const entityValue = CustomMetadataUtils.getAttributeValueByPropertyName(
      this.entity.attributeDescriptions,
      this.entity.attributeValues,
      name
    );

    if (entityValue !== undefined) {
      if (this.getAttributeTypeByPropertyName(name) === AttributeType.Image) {
        const returnValue: ImageInterface = {
          ...entityValue,
          url:
            typeof entityValue.url === 'object'
              ? entityValue.url?.value
              : entityValue.url,
        };
        return returnValue;
      }
      return entityValue;
    }
  }

  formatField(dto: Record<string, any>, key: string): void {
    super.formatField(dto, key);
    const attributeType = this.getAttributeTypeByPropertyName(key);
    const currentFormValue = dto[key];
    if (attributeType === AttributeType.Link) {
      dto[key] = this.mapToLinkPayload(currentFormValue, key);
    }
    if (attributeType === AttributeType.Image) {
      dto[key] = {
        id: currentFormValue?.id,
      };
    }
  }

  canEdit(propertyName: string, isEditting: boolean): boolean {
    return !this.notAllowUpdateFields.includes(propertyName) && isEditting;
  }

  getFormControlByPropertyName(propertyName: string): UntypedFormControl {
    return this.form.get(propertyName) as UntypedFormControl;
  }

  getAttributeTypeByPropertyName(propertyName: string): AttributeType {
    return this.getFieldOptions(propertyName, this.entity)
      ?.attributeType as AttributeType;
  }

  protected handleAfterGetEntityDetail(entity: TenantInterface): void {
    this.entity = entity;
    this.changeFormStatusSubject.next();
    this.sessionService.setTenantId(entity.id);
    this.sessionService.setTenantUrl(entity.companyURL?.url || '');
    this.sessionService.setTenantLogo(entity.logo?.url || '');
  }

  private mapToLinkPayload(formValue: string, key: string): LinkInterface {
    const currentEntityValue = this.entity[
      StringUtils.toLowerCaseFirstLetter(key)
    ] as LinkInterface;
    return {
      ...currentEntityValue,
      url: formValue,
    };
  }

  getMailConfigFieldOptions(propertyName: string): Record<string, any> {
    switch (propertyName) {
      case 'EmailSMTPPassword':
        return { isReplacedByStar: true };
      case 'EmailSalutationMessage':
        return { isViewAsText: true };
      default:
        return {};
    }
  }

  getAttributeTypeByPropName(propertyName: string): AttributeType {
    let type: AttributeType;

    switch (propertyName) {
      case 'EmailSalutationMessage':
        type = AttributeType.RTE;
        break;
      case 'EmailSMTPPassword':
        type = AttributeType.String;
        break;
      default:
        const attributeType = this.getAttributeTypeByPropertyName(propertyName);
        type =
          attributeType === AttributeType.String
            ? AttributeType.StringSingle
            : null;
        break;
    }

    return type;
  }

  preSaveSection(): Observable<boolean> {
    this.getStoredPasswordValue();
    return of(true);
  }

  getStoredPasswordValue(): void {
    const smtpPassword = this.form.get('EmailSMTPPassword').value;

    if (!smtpPassword && this.storedPassword) {
      this.form.patchValue({
        EmailSMTPPassword: this.storedPassword,
      });
    }
  }

  onStoreValueChange(val): void {
    if (!val) return;
    this.storedPassword = val;
  }

  isDefaultLogo(propertyName: string): boolean {
    return TENANT_DEFAULT_LOGO_PROPERTY.includes(propertyName);
  }

  isDefaultBanner(propertyName: string): boolean {
    return TENANT_DEFAULT_BANNER_PROPERTY.includes(propertyName);
  }
}
