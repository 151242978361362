<ng-container *ngIf="false">
  <app-metadata-table-organization
    [entityDatas]="items"
    [isLoading]="isLoading"
    [editable]="false"
  ></app-metadata-table-organization>
  <app-bootstrap-pagination
    [page]="page"
    [pageSize]="pageSize"
    [collectionSize]="collectionSize"
    (changePageSize)="onPageSizeChange($event)"
    (changePage)="onPageChange($event)"
  ></app-bootstrap-pagination>
</ng-container>
<app-filter-pane-organization
  [httpService]="listService"
  [entityName]="entityName"
  [isShowFilterPane]="true"
  [filterResultCount]="collectionSize"
  [showFilterResult]="showFilterResult"
  (displayFilterPane)="onFilterPaneDisplay($event)"
>
</app-filter-pane-organization>
<ng-container *ngIf="!isLoading">
  <div>
    <ng-container *ngIf="items && items.length > 0; else emptyContent">
      <ul
        *ngIf="items"
        [class.three-columns]="columns === '3'"
        [style.background-color]="bg_color"
        class="widget__container venture-list"
      >
        <li
          *ngFor="let organization of items; index as orgIndex"
          class="venture__item"
        >
          <div class="venture__wrapper">
            <ng-container
              *ngIf="
                getUiTemplate(organization) !== 'SIListTile';
                else switzerlandInnovationTile
              "
            >
              <a
                appUnsavedFormCheck
                class="link-unstyled link-rich-text-pointer"
                href="{{ getEntityUrl(organization) }}/{{ organization.id }}"
                target="{{ is_open_in_new_tab ? '_blank' : '_self' }}"
                rel="noopener"
              >
                <div class="venture__header">
                  <div class="organization__brand row">
                    <span class="organization__logo d-flex col-1">
                      <img
                        [src]="organization.logo?.url"
                        appDefaultPlaceholder
                        [default]="placeholderImg"
                        appDynamicImgUrl
                      />
                    </span>

                    <div class="organization__name-container col">
                      <div class="organization__name d-flex">
                        <span
                          [ngbTooltip]="organization.displayText"
                          class="text-reset truncate-line-1 underline"
                        >
                          {{ organization.orgName }}
                        </span>
                        <a
                          *ngIf="organization.orgUrl.url"
                          [ngbTooltip]="orgWebsiteRef"
                          [value]="organization.orgUrl.url"
                          appUrlPrefix
                          target="_blank"
                          class="ml-2"
                        >
                          <app-internal-icon
                            [name]="InternalIcon.GlobeCursor"
                            [height]="18"
                            [minWidth]="19.5"
                          ></app-internal-icon>
                        </a>
                        <ng-template #orgWebsiteRef>
                          <div class="d-flex flex-column align-items-center">
                            <span>{{
                              'UI.Organization.ExternalWebsiteTooltip'
                                | translate
                            }}</span>
                            <span class="text-white font-weight-bold">
                              {{ organization.orgUrl?.url }}
                            </span>
                          </div>
                        </ng-template>
                      </div>
                      <div class="organization__sub-name">
                        <span class="text-reset truncate-line-1" appTooltip>
                          <span [ngbTooltip]="organization.enclosingOrgName">
                            {{ organization.enclosingOrgName }}
                          </span>
                        </span>
                      </div>
                    </div>

                    <div class="organization__header-action pt-0">
                      <app-follow-interact
                        [entity]="organization"
                        [hasBigSize]="true"
                        [uiTemplate]="'button'"
                        (followClicked)="toggleFavorite($event)"
                      >
                      </app-follow-interact>
                    </div>
                    <div
                      class="organization__created-time d-md-none col-12 ml-5"
                    >
                      {{
                        organization.createdDate
                          | localizedDate : DateFormat.MetadataTable
                      }}
                    </div>
                  </div>

                  <app-image-wrapper [imgObject]="organization.image?.url">
                    <app-partner-status
                      [partnerStatus]="organization.partnerStatus"
                    ></app-partner-status>
                  </app-image-wrapper>

                  <div
                    class="venture__name organization__name--min-height widget__heading--background d-flex flex-column justify-content-center px-3 py-2"
                  >
                    <div
                      class="venture__title organization__title widget__heading"
                    >
                      <span
                        appTooltip
                        triggers="manual"
                        #t2="ngbTooltip"
                        [toolTipControl]="t2"
                        [ngbTooltip]="organization.headline"
                        class="truncate-line-2"
                      >
                        {{ organization.headline }}
                      </span>
                    </div>
                    <div class="widget__sub-heading">
                      <span
                        appTooltip
                        triggers="manual"
                        #t3="ngbTooltip"
                        [toolTipControl]="t3"
                        [ngbTooltip]="organization.subHeadline"
                        class="truncate-line-1"
                      >
                        {{ organization.subHeadline }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="venture__body widget__card--background">
                  <div class="row">
                    <div class="col col-12">
                      <div class="venture__info d-flex flex-column">
                        <div
                          class="d-flex justify-content-between align-items-center w-100 my-3"
                        >
                          <div class="organization__created-time">
                            {{
                              organization.createdDate
                                | localizedDate : DateFormat.MetadataTable
                            }}
                          </div>
                          <div class="d-flex">
                            <!-- More -->
                            <div class="more-options" ngbDropdown>
                              <button
                                placement="bottom-left"
                                class="btn btn-interact btn-interact--small btn-secondary-dark text-white mr-2"
                                (click)="$event.preventDefault()"
                                ngbDropdownToggle
                              >
                                <i
                                  class="far fa-ellipsis-h fa-lg align-middle"
                                ></i>
                                <span class="text-hide-small" translate>
                                  UI.Common.Button.More
                                </span>
                              </button>
                              <ul ngbDropdownMenu>
                                <!-- Ventures -->
                                <li
                                  ngbDropdownItem
                                  class="list-inline-item venture__actions-item"
                                >
                                  <ng-container
                                    *ngIf="
                                      isLoadingVenturesCount;
                                      else venturesBtn
                                    "
                                  >
                                    <div class="text-center">
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </div>
                                  </ng-container>
                                  <ng-template #venturesBtn>
                                    <a
                                      appDebounceClick
                                      (debounceClick)="
                                        showEntityData(organization);
                                        showVenturesModal = true
                                      "
                                    >
                                      <i class="fas fa-briefcase"></i>
                                      <span
                                        class="venture__actions-label font-weight-bold"
                                      >
                                        <span class="">{{
                                          'Ventures' | translate
                                        }}</span>
                                        <span
                                          [innerHTML]="
                                            organization.venturesCount
                                              | parentheses
                                          "
                                        ></span>
                                      </span>
                                    </a>
                                  </ng-template>
                                </li>

                                <!-- Events -->
                                <li
                                  ngbDropdownItem
                                  class="list-inline-item venture__actions-item"
                                >
                                  <ng-container
                                    *ngIf="isLoadingEventsCount; else eventsBtn"
                                  >
                                    <div class="text-center">
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </div>
                                  </ng-container>
                                  <ng-template #eventsBtn>
                                    <a
                                      appDebounceClick
                                      (debounceClick)="
                                        showEntityData(organization);
                                        showEventsModal = true
                                      "
                                    >
                                      <i class="fas fa-folder-open"></i>
                                      <span
                                        class="venture__actions-label font-weight-bold"
                                      >
                                        {{ 'Events' | translate }}
                                        <span
                                          [innerHTML]="
                                            organization.eventsCount
                                              | parentheses
                                          "
                                        ></span>
                                      </span>
                                    </a>
                                  </ng-template>
                                </li>
                              </ul>
                            </div>

                            <app-join-organization
                              [baseHttpService]="listService"
                              [entity]="organization"
                            ></app-join-organization>
                          </div>
                        </div>
                        <div
                          class="venture__description organization-description"
                        >
                          <div class="venture__label widget__label" translate>
                            Description
                          </div>
                          <app-rich-text-toggle
                            [text]="organization.description"
                            [showOverlayInsteadOfShowMore]="true"
                            [controlName]="'org-description-' + organization.id"
                            [hideSeeMore]="true"
                            [controlStyleByCentralConfig]="true"
                            [minNumOfRows]="5"
                          ></app-rich-text-toggle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="venture__body widget__card--background organization__keyOfferings"
                >
                  <div class="row">
                    <div class="col col-12 mt-2">
                      <div
                        class="d-flex pointer-cursor"
                        appDebounceClick
                        (debounceClick)="
                          showEntityData(organization); showKeyOfferModal = true
                        "
                        (click)="selectedOrg = organization"
                      >
                        <div class="venture__label widget__label" translate>
                          UI.Organization.KeyOfferings
                        </div>

                        <span
                          [innerHTML]="
                            organization.keyOfferings.length | parentheses
                          "
                        ></span>
                      </div>

                      <ul
                        appTooltip
                        triggers="manual"
                        #t="ngbTooltip"
                        [toolTipControl]="t"
                        [ngbTooltip]="keyOfferings"
                        tooltipClass="no-limit-width-tooltip"
                        class="list-inline tooltip-wrapper widget__highlight"
                      >
                        <ng-container
                          *ngTemplateOutlet="keyOfferings"
                        ></ng-container>
                      </ul>
                      <ng-template #keyOfferings>
                        <li
                          *ngFor="let keyOffering of organization.keyOfferings"
                          class="list-inline-item"
                        >
                          <span
                            class="venture__characteristic tooltip-description text-truncate widget__characteristics--background widget__highlight badge badge-pill badge-light"
                          >
                            {{ keyOffering.description }}
                          </span>
                        </li>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </a>
            </ng-container>

            <app-interaction-bar
              entityPath="{{ getEntityUrl(organization) }}/{{
                organization.id
              }}"
              [httpService]="listService"
              [entity]="organization"
              [entityName]="entityName"
              [isLoadingLikingsCount]="isLoadingLikingsCount"
              [isLoadingFollowingsCount]="isLoadingFollowingsCount"
              [isLoadingCommentsCount]="isLoadingCommentsCount"
              [hideLikeNumber]="getUiTemplate(organization) === 'SIListTile'"
              [useFollowIcon]="getUiTemplate(organization) === 'SIListTile'"
              (triggerToggleLike)="toggleLike(organization)"
              (triggerShowLikingsData)="
                showEntityData(organization);
                showLikesModal = !!organization?.likesCount
              "
              (triggerShowFollowingsData)="
                showEntityData(organization); showPeopleModal = true
              "
              (afterCommentModalClosed)="onCommentModalClosed()"
              (triggerToggleFavorite)="toggleFavorite(organization)"
            >
            </app-interaction-bar>

            <ng-template #switzerlandInnovationTile>
              <a
                appUnsavedFormCheck
                class="link-unstyled link-rich-text-pointer"
                href="{{ getEntityUrl(organization) }}/{{ organization.id }}"
                target="{{ is_open_in_new_tab ? '_blank' : '_self' }}"
                rel="noopener"
              >
                <div class="venture__header">
                  <div>
                    <div class="custom-organization__brand">
                      <div
                        class="custom-organization__logo d-flex mb-3 justify-content-center"
                      >
                        <img
                          class="mt-4"
                          [src]="organization.logo?.url"
                          appDefaultPlaceholder
                          [default]="placeholderImg"
                          appDynamicImgUrl
                        />
                      </div>

                      <div class="custom-organization__name-container">
                        <div class="custom-organization__name d-flex">
                          <span
                            [ngbTooltip]="organization.displayText"
                            class="text-reset truncate-line-1 underline"
                          >
                            {{ organization.orgName }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="venture__body widget__card--background">
                  <div class="row">
                    <div class="col col-12">
                      <div class="venture__info d-flex flex-column">
                        <div
                          class="d-flex justify-content-between align-items-center w-100 my-2"
                        >
                          <div
                            class="d-flex justify-content-between align-items-center custom-organization__button-wrapper"
                          >
                            <app-join-organization
                              [baseHttpService]="listService"
                              [entity]="organization"
                              [hideTextSmall]="false"
                            ></app-join-organization>

                            <!-- More -->
                            <div class="more-options" ngbDropdown>
                              <button
                                placement="bottom-left"
                                class="btn btn-interact btn-interact--small btn-secondary-dark text-white ml-2"
                                (click)="$event.preventDefault()"
                                ngbDropdownToggle
                              >
                                <span translate> More </span>
                                <i class="fas fa-caret-down ml-1 mr-0"></i>
                              </button>
                              <ul ngbDropdownMenu>
                                <!-- Ventures -->
                                <li
                                  ngbDropdownItem
                                  class="list-inline-item venture__actions-item"
                                >
                                  <ng-container
                                    *ngIf="
                                      isLoadingVenturesCount;
                                      else venturesBtn
                                    "
                                  >
                                    <div class="text-center">
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </div>
                                  </ng-container>
                                  <ng-template #venturesBtn>
                                    <a
                                      appDebounceClick
                                      (debounceClick)="
                                        showEntityData(organization);
                                        showVenturesModal = true
                                      "
                                    >
                                      <i class="fas fa-briefcase"></i>
                                      <span
                                        class="venture__actions-label font-weight-bold"
                                      >
                                        <span class="">{{
                                          'Ventures' | translate
                                        }}</span>
                                        <span
                                          [innerHTML]="
                                            organization.venturesCount
                                              | parentheses
                                          "
                                        ></span>
                                      </span>
                                    </a>
                                  </ng-template>
                                </li>

                                <!-- Events -->
                                <li
                                  ngbDropdownItem
                                  class="list-inline-item venture__actions-item"
                                >
                                  <ng-container
                                    *ngIf="isLoadingEventsCount; else eventsBtn"
                                  >
                                    <div class="text-center">
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </div>
                                  </ng-container>
                                  <ng-template #eventsBtn>
                                    <a
                                      appDebounceClick
                                      (debounceClick)="
                                        showEntityData(organization);
                                        showEventsModal = true
                                      "
                                    >
                                      <i class="fas fa-folder-open"></i>
                                      <span
                                        class="venture__actions-label font-weight-bold"
                                      >
                                        {{ 'Events' | translate }}
                                        <span
                                          [innerHTML]="
                                            organization.eventsCount
                                              | parentheses
                                          "
                                        ></span>
                                      </span>
                                    </a>
                                  </ng-template>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div
                            class="d-flex justify-content-between align-items-center custom-organization__external-website"
                            *ngIf="organization.orgUrl.url"
                          >
                            <a
                              [ngbTooltip]="orgWebsiteRef"
                              [value]="organization.orgUrl.url"
                              appUrlPrefix
                              target="_blank"
                              class="d-flex align-items-center"
                            >
                              <app-internal-icon
                                [name]="InternalIcon.SIGlobeCursor"
                                [height]="18"
                                [minWidth]="19.5"
                              ></app-internal-icon>
                              <span class="ml-1">Website</span>
                            </a>
                            <ng-template #orgWebsiteRef>
                              <div
                                class="d-flex flex-column align-items-center"
                              >
                                <span>{{
                                  'UI.Organization.ExternalWebsiteTooltip'
                                    | translate
                                }}</span>
                                <span class="text-white font-weight-bold">
                                  {{ organization.orgUrl?.url }}
                                </span>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                        <div class="venture__description mt-2">
                          <div
                            class="simple-card__description widget__content"
                            style="font-size: 14px; line-height: 1.2rem"
                          >
                            <p
                              appEllipsis
                              [innerHTML]="organization.description"
                              class="m-0"
                              style="max-height: 100px"
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="venture__body widget__card--background">
                  <div>
                    <div class="custom-organization__label" translate>
                      Innovation Focus
                    </div>
                    <div class="custom-organization__content">
                      {{ organization.innovationFocus?.value }}
                    </div>
                  </div>
                  <div class="mt-2">
                    <div class="custom-organization__label" translate>
                      Resident at
                    </div>
                    <div class="custom-organization__content">
                      {{ organization.residentOf?.value }}
                    </div>
                  </div>
                  <div class="mt-2">
                    <div class="custom-organization__label" translate>
                      Key offerings
                      <span
                        [innerHTML]="
                          organization.keyOfferings.length | parentheses
                        "
                      ></span>
                    </div>
                    <div
                      class="custom-organization__content truncate-line-1"
                      appTooltip
                      triggers="manual"
                      #t="ngbTooltip"
                      [toolTipControl]="t"
                      [ngbTooltip]="keyOfferings"
                      tooltipClass="no-limit-width-tooltip"
                    >
                      <ng-container
                        *ngFor="
                          let keyOffering of organization.keyOfferings;
                          index as i
                        "
                      >
                        <span
                          >{{ keyOffering.description
                          }}{{
                            i + 1 !== organization.keyOfferings.length
                              ? ', '
                              : ''
                          }}</span
                        >
                      </ng-container>

                      <ng-template #keyOfferings>
                        <li
                          *ngFor="let keyOffering of organization.keyOfferings"
                          class="list-inline-item"
                        >
                          <span
                            class="venture__characteristic tooltip-description text-truncate widget__characteristics--background widget__highlight badge badge-pill badge-light"
                          >
                            {{ keyOffering.description }}
                          </span>
                        </li>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </a>
              <div
                class="my-2 px-3 custom-organization__social-interact-wrapper d-flex justify-content-between align-items-center"
                [ngClass]="{
                  'flex-row-reverse': !organization.likesCount
                }"
              >
                <div
                  *ngIf="organization.likesCount"
                  class="custom-organization__social-interact-wrapper--like d-flex align-items-center pointer-cursor"
                  (click)="
                    showEntityData(organization);
                    showLikesModal = !!organization?.likesCount
                  "
                >
                  <span class="like-icon">
                    <i class="fas fa-thumbs-up"></i>
                  </span>

                  <span class="ml-1">{{ organization.likesCount }}</span>
                </div>
                <div
                  class="custom-organization__social-interact-wrapper--comment-follower d-flex align-items-center pointer-cursor"
                  (click)="showEntityData(organization); showPeopleModal = true"
                >
                  <div
                    *ngIf="organization.followerCount"
                    class="d-flex align-items-center"
                  >
                    <span>{{ organization.followerCount }}</span>
                    <span class="ml-1">{{
                      'Followers' | translate | lowercase
                    }}</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </li>
      </ul>
    </ng-container>
    <ng-template #emptyContent>
      <app-empty-content *ngIf="!hasFilterPane"></app-empty-content>
    </ng-template>
    <app-bootstrap-pagination
      *ngIf="show_paginator"
      [page]="page"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize"
      (changePageSize)="onPageSizeChange($event)"
      (changePage)="onPageChange($event)"
    ></app-bootstrap-pagination>

    <div
      *ngIf="show_creation_btn"
      class="text-right widget__btn-create-wrapper"
    >
      <button
        class="btn btn-primary widget__btn-create my-3"
        translate
        appRequireLogin
        [callBackLoginPage]="entityCreatepath"
      >
        <i class="fas fa-plus fa-lg"></i>
      </button>
    </div>
  </div>
</ng-container>

<app-key-offer-modal
  *ngIf="showKeyOfferModal && !!selectedOrg?.keyOfferings?.length"
  [modalTitle]="'UI.Organization.KeyOfferings' | translate"
  [entityId]="selectedEntityId"
  [keyOfferings]="selectedOrg.keyOfferings"
  (dismissModal)="showKeyOfferModal = false"
>
</app-key-offer-modal>

<app-people-modal
  *ngIf="showPeopleModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.orgName"
  [modalTitle]="'Followers' | translate"
  [httpService]="listService"
  (dismissModal)="showPeopleModal = false"
></app-people-modal>

<app-events-modal
  *ngIf="showEventsModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.orgName"
  [modalTitle]="'Events' | translate"
  [httpService]="listService"
  (dismissModal)="showEventsModal = false"
></app-events-modal>

<app-ventures-modal
  *ngIf="showVenturesModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.orgName"
  [modalTitle]="'Ventures' | translate"
  [httpService]="listService"
  [globalOrgId]="orgId"
  (dismissModal)="showVenturesModal = false"
></app-ventures-modal>

<app-likes-modal
  *ngIf="showLikesModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.orgName"
  [modalTitle]="'UI.Likes.Organization' | translate"
  [httpService]="listService"
  (dismissModal)="showLikesModal = false"
></app-likes-modal>
