<div class="event-card-footer challenge-card-footer">
  <div class="event-card-footer__event-date">
    <div class="challenge-card-footer--small-label">Date & Time</div>
    <ng-container
      *ngIf="event.eventDateTime | date : 'dd.MM.yyyy | HH:mm' as eventDateTime"
    >
      <span
        [ngbTooltip]="eventDateTime"
        class="challenge-card-footer--medium-label text-truncate d-block w-100"
      >
        {{ eventDateTime }}
      </span>
    </ng-container>
  </div>

  <div class="event-card-footer__location">
    <span
      class="challenge-card-footer--medium-label d-flex align-items-end truncate-line-1"
    >
      <div class="mw-100">
        <app-event-external-register-button
          *ngIf="event.showExternalRegistration && event.registrationLink"
          (click)="$event.stopPropagation()"
          [event]="event"
        ></app-event-external-register-button>
      </div>
      <ng-container *ngIf="!event.showExternalRegistration">
        <div *ngIf="!event.hasJoined" class="mt-1">
          <app-join-event
            btnStyle="minimal"
            [entity]="event"
            [baseHttpService]="eventService"
            (afterJoined)="afterJoinEvent(event)"
          ></app-join-event>
        </div>

        <div *ngIf="event.hasJoined">
          <ng-container
            *ngIf="
              event.attributeValues | attributeValue : 'Location' as location;
              else virtualLocationTemplate
            "
          >
            <div
              *ngIf="
                location.describedValue?.value
                  | jsonParse : 'url' as locationLink;
                else virtualLocationTemplate
              "
            >
              <a
                class="event-card-footer--base-blue"
                target="_blank"
                [value]="locationLink"
                appUrlPrefix
              >
                {{ 'Go To Location' | translate }}
              </a>
            </div>
          </ng-container>

          <ng-template #virtualLocationTemplate>
            <ng-container
              *ngIf="
                event.attributeValues
                  | attributeValue : 'VirtualLocation' as virtualLocation
              "
            >
              <ng-container
                class="event-cart-footer__link"
                *ngIf="virtualLocation.describedValue?.url as locationLink"
              >
                <a
                  class="event-card-footer--base-blue"
                  target="_blank"
                  appUrlPrefix
                  value="{{ locationLink.value || locationLink }}"
                  >{{ 'Go To Location' | translate }}</a
                >
              </ng-container>
            </ng-container>
          </ng-template>
        </div>
      </ng-container>
    </span>
  </div>
</div>
