export enum VentureSectionIndex {
  Title,
  Phase,
  Description,
  Demands,
  LeadCompany,
  BusinessAreas,
  Stakeholders,
  Link,
  SubmittedOrganizations,
  Status,
  TimeStamp,
}

export enum VentureOriginField {
  Title = 'Title',
  Content = 'Content',
  Excerpt = 'Excerpt',
  Image = 'Image',
  Terms = 'Terms',
}

export enum VentureSubmissionStatus {
  Submitted = 'Submitted',
  Accepted = 'Accepted',
  Refused = 'Refused',
  Awarded = 'Awarded',
}

export enum VentureStateCodeId {
  Active = 'VentureStatusActiveOpenAdditionalStakeholders',
  Suspended = 'VentureStatusSuspended',
  Closed = 'VentureStatusClosed',
  Deactivated = 'VentureStatusDeactivated',
}

export enum JoinVentureState {
  HasJoined = 1,
  IsPending,
  NotAccepted,
  CanJoin,
}

export enum FilterPaneUi {
  Default = 'default',
  Simple = 'simple'
}