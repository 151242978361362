export enum UrlParam {
  OrganizationId = 'organisation-id',
  IsChallenge = 'is-challenge',
  TimestampId = 'timestampId',
  Token = 'token',
  ChangeFor = 'change-for',
  Elementor = 'elementor',
  ElementorPreview = 'elementor-preview',
  PageNumber = 'pageNumber',
  FilterTokenId = 'organizationId',
  TenantSection = 'tenantSection',
  Code = 'code',
  ResendEmail = 'resend-email',
  PreviousAction = 'previous-action',
  RegisterEvent = 'register-event',
  Error = 'error',
  RequiredLogin = 'required-login',
  Email = 'email',
  Register = 'register',
  SessionId = 'ssid',
  LeadOrgId = 'lead-org-id',
  OrgIds = 'OrgIds',
  IsIncludeMyself = 'isIncludeMyself',
  Action = 'action',
  TabNews = 'tabNews',
  AssignToOrg = 'assign-to-org-id',
  AutoAssignment = 'auto-assignment',
  GlobalSearchTab = 'global-search-tab',
  SubOrganizationId = 'sub-organization',
  NotFound = 'notFound',
}
