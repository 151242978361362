import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormLoginComponent } from '@components/auth/login/form-login/form-login.component';
import { AuthenService } from '@core/authen/authen.service';
import { FormErrorService } from '@core/form/form-error.service';
import { CentralConfigService } from '@core/services/central-config.service';
import { SessionService } from '@core/session.service';
import { ToBoolean } from '@shared/decorators/to-boolean';
import { FormUtils } from '@shared/utils/form-utils';
import { CookieService } from 'ngx-cookie-service';

enum ELoginButtonPosition {
  Top = 'top',
  Bottom = 'bottom',
}
@Component({
  selector: 'app-simple-form-login',
  templateUrl: './simple-form-login.component.html',
  providers: [FormErrorService],
})
export class SimpleFormLoginComponent
  extends FormLoginComponent
  implements OnInit
{
  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() auto_assign_to_org_enabled = true;
  @Input() @ToBoolean() applyPendingToAutoAssignment: boolean;
  @Input() loginButtonPosition: ELoginButtonPosition;
  @Input() usernameControlClass: string;
  @Input() passwordControlClass: string;
  @Input() loginButtonClass: string;
  @Input() redirectUrlAfterLoginSuccess = '';

  loginButtonPositionEnum = ELoginButtonPosition;

  constructor(
    public authService: AuthenService,
    public formErrorService: FormErrorService,
    protected fb: UntypedFormBuilder,
    protected sessionService: SessionService,
    private centralConfig: CentralConfigService,
    public cookieService: CookieService
  ) {
    super(fb, authService, formErrorService, sessionService, cookieService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.assignToOrgId = this.auto_assign_to_org_enabled
      ? this.centralConfig.innovationSpaceId
      : null;
    this.loginSuccess.subscribe(() => {
      this.onLoginSuccess();
    });
  }

  onLoginSuccess(): void {
    this.authService.updateProfileInfo();
    const redirectUrl = this.redirectUrlAfterLoginSuccess
      ? this.redirectUrlAfterLoginSuccess
      : window.location.href;
    FormUtils.navigateTo(redirectUrl);
  }
}
