<ng-container *ngIf="!isLoading">
  <div>
    <ng-container *ngIf="items && items.length > 0; else emptyContent">
      <ul
        *ngIf="items"
        [class.three-columns]="columns === '3'"
        [style.background-color]="bg_color"
        class="widget__container venture-list event-list"
      >
        <li
          *ngFor="let event of items; index as eventIndex"
          class="venture__item"
        >
          <div class="venture__wrapper">
            <a
              appUnsavedFormCheck
              class="link-unstyled link-rich-text-pointer"
              href="{{ entitybase }}/{{ event.id }}"
              target="{{ is_open_in_new_tab ? '_blank' : '_self' }}"
              rel="noopener"
            >
              <div class="venture__header">
                <div class="organization__brand">
                  <ng-container *ngIf="event.leadCompany; else deactiveOrg">
                    <span
                      class="organization__logo d-flex col-1"
                      [ngClass]="{ 'pointer-none': !event.leadCompany.id }"
                    >
                      <a
                        [href]="event.leadCompany | challengeOrgUrl"
                        appUrlPrefix
                        class="text-reset"
                        target="_blank"
                      >
                        <img
                          [src]="event.leadCompany.logo?.url"
                          appDefaultPlaceholder
                          [default]="placeholderImg"
                          appDynamicImgUrl
                        />
                      </a>
                    </span>

                    <div class="organization__name-container col">
                      <div class="organization__name">
                        <a
                          class="text-reset truncate-line-1"
                          [value]="event.leadCompany.url"
                          rel="noopener"
                          appUrlPrefix
                          appTooltip
                          target="_blank"
                        >
                          <span [ngbTooltip]="event.leadCompany.name">
                            {{ event.leadCompany.name }}
                          </span>
                        </a>
                      </div>
                      <div class="organization__sub-name">
                        <span class="text-reset truncate-line-1" appTooltip>
                          <span [ngbTooltip]="event.leadCompany.enclosingName">
                            {{ event.leadCompany.enclosingName }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #deactiveOrg>
                    <span class="organization__logo d-flex col-1">
                      <app-deactive-entity
                        [entityName]="EntityName.Organization"
                      ></app-deactive-entity>
                    </span>

                    <div class="organization__name-container col">
                      <div class="deactive-label truncate-line-1">
                        {{ 'UI.UnsubscribedOrganization' | translate }}
                      </div>
                    </div>
                  </ng-template>

                  <div class="d-flex align-items-center flex-column">
                    <div class="d-flex align-items-center">
                      <ng-container
                        *ngIf="
                          event.showExternalRegistration &&
                          event.registrationLink
                        "
                      >
                        <div
                          [ngbTooltip]="
                            event | eventRegisterTooltip | translate
                          "
                        >
                          <div class="event-register-btn">
                            <a
                              [class.disable-register]="
                                event | eventRegisterBtnDisabled
                              "
                              appUnsavedFormCheck
                              [value]="event.registrationLink"
                              target="_blank"
                              class="btn btn-interact btn-interact--big btn-primary"
                              appUrlPrefix
                              appTooltip
                              (click)="handleExternalRegister($event, event)"
                            >
                              <i class="register-icon fas fa-file-medical"></i>
                              <span class="text-hide-small">{{
                                'UI.Event.ExternalRegistration' | translate
                              }}</span>
                            </a>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!event.showExternalRegistration">
                        <app-join-event
                          [entity]="event"
                          [entityName]="entityName"
                          [baseHttpService]="listService"
                          (afterEventPassed)="afterEventPassed(event)"
                          (afterJoined)="afterJoinEvent(event)"
                          (leaveEntity)="afterLeaveEntity(event)"
                          [isOwner]="
                            event?.initiator?.id === (profile$ | async)?.id
                          "
                        >
                        </app-join-event>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <a
                  appUnsavedFormCheck
                  href="{{ entitybase }}/{{ event.id }}"
                  class="link-unstyled"
                >
                  <app-image-wrapper [imgObject]="event.image">
                    <app-event-stamp [event]="event"></app-event-stamp>
                    <app-event-location-flag
                      [event]="event"
                      [uiPosition]="locationFlagPosition"
                    ></app-event-location-flag>
                  </app-image-wrapper>
                  <div
                    class="venture__name venture__name--min-height widget__heading--background d-flex align-items-center px-3 py-2"
                  >
                    <h5
                      class="venture__title widget__heading truncate-line-2"
                      appTooltip
                      triggers="manual"
                      #t="ngbTooltip"
                      [toolTipControl]="t"
                      [ngbTooltip]="event.title"
                    >
                      {{ event.title }}
                    </h5>
                  </div>
                </a>
              </div>

              <div class="venture__body widget__card--background">
                <div class="row">
                  <div class="col col-12">
                    <div class="venture__info d-flex flex-column">
                      <div
                        class="d-flex justify-content-between align-items-center w-100 my-3"
                      >
                        <div class="d-flex">
                          <app-privacy-flag [isPrivate]="event.isPrivate">
                          </app-privacy-flag>
                        </div>
                        <div class="d-flex">
                          <button
                            *ngIf="
                              event.showExternalRegistration || event.hasJoined
                            "
                            appAddToCalendar
                            class="btn btn-interact btn-interact--small btn-secondary-dark text-white mr-2"
                            [event]="event"
                          >
                            <i class="fas fa-calendar-plus"></i>
                            <span class="text-hide-small">
                              {{ 'UI.Event.AddToCalendar' | translate }}
                            </span>
                          </button>

                          <app-follow-interact
                            [entity]="event"
                            (followClicked)="toggleFavorite($event)"
                          >
                          </app-follow-interact>
                        </div>
                      </div>

                      <div class="d-flex w-100 flex-column flex-md-row">
                        <div class="d-flex flex-column col-12 col-md-6 p-0">
                          <div class="venture__label widget__label" translate>
                            UI.StartDate
                          </div>

                          <div class="widget__content">
                            {{
                              event.eventDateTime
                                | localizedDate : DateFormat.FullDateTimeCommon3
                            }}
                          </div>
                        </div>
                        <div class="d-flex flex-column col-12 col-md-6 p-0">
                          <div class="venture__label widget__label" translate>
                            UI.EndDate
                          </div>

                          <div class="widget__content">
                            {{
                              getEventEndDate(
                                event.eventDateTime,
                                event.eventLength
                              ) | localizedDate : DateFormat.FullDateTimeCommon3
                            }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="d-flex w-100 flex-column flex-md-row mt-sm-3 mt-0"
                      >
                        <div class="d-flex flex-column col-12 col-md-6 p-0">
                          <div class="venture__label widget__label">
                            {{ languageAttrDes?.displayName }}
                          </div>

                          <div class="widget__content">
                            {{ event.language?.value }}
                          </div>
                        </div>

                        <div
                          class="d-flex flex-column col-12 col-md-6 p-0"
                          *ngIf="!event.showExternalRegistration"
                        >
                          <div class="venture__label widget__label">
                            {{ participantAttrDes?.displayName }}
                          </div>

                          <div class="widget__content">
                            <ng-container
                              *ngIf="
                                isLoadingParticipantsCount;
                                else participantsCountRef
                              "
                            >
                              <div class="text-center">
                                <i class="fas fa-spinner fa-spin"></i>
                              </div>
                            </ng-container>
                            <ng-template #participantsCountRef>
                              {{ event.participantsCount }}
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <app-interaction-bar
              entityPath="{{ entitybase }}/{{ event.id }}"
              [httpService]="listService"
              [entity]="event"
              [entityName]="entityName"
              [isLoadingLikingsCount]="isLoadingLikingsCount"
              [isLoadingFollowingsCount]="isLoadingFollowingsCount"
              [isLoadingCommentsCount]="isLoadingCommentsCount"
              [hideShareIcon]="event.isPrivate"
              (triggerToggleLike)="toggleLike(event)"
              (triggerShowLikingsData)="
                showEntityData(event); showLikesModal = !!event?.likesCount
              "
              (triggerShowFollowingsData)="
                showEntityData(event); showPeopleModal = true
              "
              (afterCommentModalClosed)="onCommentModalClosed()"
            >
            </app-interaction-bar>
          </div>
        </li>
      </ul>
    </ng-container>
    <ng-template #emptyContent>
      <app-empty-content></app-empty-content>
    </ng-template>
    <app-bootstrap-pagination
      *ngIf="show_paginator"
      [page]="page"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize"
      (changePageSize)="onPageSizeChange($event)"
      (changePage)="onPageChange($event)"
    ></app-bootstrap-pagination>

    <div
      *ngIf="show_creation_btn"
      class="text-right widget__btn-create-wrapper"
    >
      <button
        class="btn btn-primary widget__btn-create my-3"
        translate
        appRequireLogin
        [callBackLoginPage]="entityCreatepath"
      >
        <i class="fas fa-plus fa-lg"></i>
      </button>
    </div>
  </div>
</ng-container>

<app-people-modal
  *ngIf="showPeopleModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.title"
  [modalTitle]="'Followers' | translate"
  [httpService]="listService"
  (dismissModal)="showPeopleModal = false"
></app-people-modal>

<app-likes-modal
  *ngIf="showLikesModal"
  [entityId]="selectedEntityId"
  [entityTitle]="selectedEntity?.title"
  [modalTitle]="'UI.Likes.Event' | translate"
  [httpService]="listService"
  (dismissModal)="showLikesModal = false"
></app-likes-modal>
