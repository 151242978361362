<ng-container *ngIf="entity">
  @if(uiTemplate === 'button') {
  <button
    class="btn btn-interact"
    [class.btn-interact--small]="!hasBigSize"
    [class.btn-interact--big]="hasBigSize"
    [class.btn-outline-primary]="!entity.isFavorite"
    [class.btn-primary]="entity.isFavorite"
    appRequireLogin
    appDebounceClick
    (debounceClick)="toggleFavorite(entity)"
  >
    <ng-container *ngIf="entity.isFavorite; else willFollow">
      <app-internal-icon
        class="mr-lg-1"
        [name]="'icon-following'"
        [height]="14"
      ></app-internal-icon>
      <span class="text-hide-small">
        {{ 'UI.Interaction.Following' | translate }}
      </span>
    </ng-container>
    <ng-template #willFollow>
      <span>{{ 'UI.Interaction.Follow' | translate }} </span>
    </ng-template>
  </button>
  } @else {
  <a appDebounceClick (debounceClick)="toggleFavorite(entity)" class="d-flex pointer-cursor">
    <app-internal-icon
      [className]="!entity.isFavorite ? 'filter-gray' : 'icon-follow following'"
      [name]="'user-follow'"
    ></app-internal-icon>
    <span class="venture__actions-label">
      <span class="text-hide-small" [class.text-primary]="entity.isFavorite">{{
        entity.isFavorite
          ? ('UI.Interaction.Following' | translate)
          : ('UI.Interaction.Follow' | translate)
      }}</span>
    </span>
  </a>
  }
</ng-container>
