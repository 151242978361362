<div class="d-flex align-items-center">
  <div
    class="d-flex align-items-center justify-content-between justify-content-sm-start"
    *ngIf="showActiveInnovationSpaceImg"
  >
    <div *ngIf="!standalone_widget_mode" class="logo-fix-size">
      <span
        *ngIf="
          selectedOrg?.imgUrl || (!selectedOrg?.imgUrl && !tenantLogo);
          else tenantLogoTemp
        "
        class="organization__logo d-flex col-1 mx-3 pointer-cursor"
      >
        <span
          (click)="navigateToActiveInnovationSpaceDetail(selectedOrg?.id)"
          appUnsavedFormCheck
        >
          <img
            [src]="selectedOrg?.imgUrl"
            appDefaultPlaceholder
            appDynamicImgUrl
          />
        </span>
      </span>
    </div>

    <ng-template #tenantLogoTemp>
      <div
        class="tenant-logo d-flex align-items-center logo-fix-size pointer-cursor"
      >
        <span
          (click)="navigateToActiveInnovationSpaceDetail()"
          appUnsavedFormCheck
        >
          <img
            alt="tenant-logo"
            [src]="tenantLogo"
            appDefaultPlaceholder
            appDynamicImgUrl
          />
        </span>
      </div>
    </ng-template>
  </div>

  <div class="align-items-center d-flex d-lg-none">
    <ng-container *ngTemplateOutlet="filterApplied"></ng-container>
  </div>
  <div
    class="global-filter flex-grow-1"
    [ngClass]="{ show: isMobileFilterMode }"
  >
    <ng-container *ngIf="sessionService?.isOnListPage">
      <div class="global-filter__close">
        <button type="button" class="btn-close close" (click)="closeFilters()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </ng-container>

    <div class="d-flex flex-column w-100">
      <ng-container
        *ngIf="
          (sessionService?.isOnListPage && showSearchBar) ||
          standalone_widget_mode
        "
      >
        <div class="global-filter__search">
          <form
            (submit)="search()"
            action="#"
            class="global-filter__search-form"
          >
            <input
              type="text"
              name="query"
              class="form-control global-filter__search-input"
              placeholder="{{ 'Search' | translate }}"
              [(ngModel)]="searchKeyword"
            />
          </form>

          <div class="search-control">
            <em
              *ngIf="this.searchKeyword"
              appDebounceClick
              (debounceClick)="resetSearch()"
              class="reset-search far fa-times mr-3"
              focusable="false"
            >
            </em>

            <i
              appDebounceClick
              (debounceClick)="search()"
              class="fa fa-search pointer-cursor"
            ></i>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          sessionService?.isOnDetailPage ||
          sessionService?.isOnListPage ||
          (!sessionService?.isOnListPage && !noFilterApplied)
        "
      >
        <ng-container *ngTemplateOutlet="filterApplied"></ng-container>
      </ng-container>
    </div>

    <ng-template #filterItems>
      <div
        (click)="onClickItem('box-all-innovation')"
        class="pointer-cursor d-flex"
        ngbDropdownItem
      >
        <input
          (change)="selectAll(organizationId, $event)"
          [(ngModel)]="allInnovation"
          id="box-all-innovation"
          type="checkbox"
        />

        <input
          [checked]="!organizationId && !isMyOrgs && !isMyInvolvement"
          class="pointer-cursor"
          type="radio"
        />

        <label
          [class.selected-filter]="
            !organizationId && !isMyOrgs && !isMyInvolvement
          "
          class="flex-grow-1 font-size-sm pointer-cursor"
          translate
          >All Innovation Spaces</label
        >
      </div>

      <ng-container *ngIf="isLogin$ | async">
        <div
          (click)="onClickItem('box-my-involvement')"
          class="pointer-cursor d-flex"
          ngbDropdownItem
        >
          <input
            (change)="filterMyEnvolvement()"
            [(ngModel)]="isMyInvolvement"
            id="box-my-involvement"
            type="checkbox"
          />

          <input
            [checked]="isMyInvolvement"
            class="pointer-cursor"
            type="radio"
          />

          <label
            [class.selected-filter]="isMyInvolvement"
            class="flex-grow-1 font-size-sm pointer-cursor truncate-line-1"
            translate
            >My Involvement</label
          >
        </div>

        <div
          (click)="onClickItem('box-my-org')"
          class="pointer-cursor d-flex"
          ngbDropdownItem
        >
          <input
            (change)="filterMyOrgs()"
            [(ngModel)]="isMyOrgs"
            id="box-my-org"
            type="checkbox"
          />

          <input [checked]="isMyOrgs" class="pointer-cursor" type="radio" />

          <label
            [class.selected-filter]="isMyOrgs"
            class="flex-grow-1 font-size-sm pointer-cursor truncate-line-1"
            translate
            >My Organizations</label
          >
        </div>
      </ng-container>

      <div class="filter__orgs">
        <ng-container *ngFor="let orgFilter of orgFilterList">
          <div
            (click)="onClickItem('box-org-' + orgFilter.id)"
            *ngIf="orgFilter | call : shouldShowFilterToken"
            class="d-flex pointer-cursor align-items-center"
            ngbDropdownItem
            [ngbTooltip]="orgFilter.name"
          >
            <input
              [checked]="organizationId === orgFilter.id"
              class="pointer-cursor"
              type="radio"
            />

            <input
              (click)="
                organizationId !== orgFilter.id &&
                  filterOrganization(orgFilter.id)
              "
              [id]="'box-org-' + orgFilter.id"
              [ngModel]="organizationId === orgFilter.id"
              type="checkbox"
            />

            <app-organization-logo
              [className]="'filter__org-logo mr-3'"
              [filterTokenUrl]="getFilterTokenUrl(orgFilter.id)"
              [organizationLogo]="orgFilter.imgUrl"
              showCopyText="true"
            ></app-organization-logo>

            <label
              [class.selected-filter]="organizationId === orgFilter.id"
              class="flex-1 truncate-line-1 d-inline-block font-size-sm pointer-cursor"
              >{{ orgFilter.name }}
            </label>

            <i
              (click)="$event.stopPropagation(); removeItem(orgFilter)"
              class="fas fa-times ml-2 filter__orgs--gray pointer-cursor"
            ></i></div
        ></ng-container>
      </div>
    </ng-template>
    <ng-template #filterApplied>
      <div
        *ngIf="!centralConfig.innovationSpaceId && !hide_innovation_space"
        class="global-filter__applied d-flex align-items-center py-2"
      >
        <div
          *ngIf="
            sessionService?.isOnDetailPage ||
            (sessionService?.isOnListPage && !isMobileFilterMode)
          "
          class="filter m-0 mr-2"
        >
          <!--          Dropdown block-->
          <ng-container *ngIf="!isMobileFilterMode">
            <div
              #dropdownRef
              (openChange)="positioningDropdown($event)"
              [autoClose]="'outside'"
              [placement]="dropDownPlacement"
              class="d-inline-block"
              container="body"
              ngbDropdown
            >
              <button
                (click)="checkDropDown(dropdownRef)"
                [class.filter__btn--active]="hasFilters"
                [disableTooltip]="isTouchDevice || dropdown?.isOpen()"
                [ngbTooltip]="tooltip"
                [placement]="dropDownPlacement"
                class="filter__btn"
                container="body"
                id="filterCollapse"
                ngbDropdownToggle
              >
                <app-internal-icon
                  [hasFilter]="hasFilters"
                  [height]="20"
                  [iconType]="IconType.PNG"
                  [name]="InternalIcon.InnovationSpace"
                ></app-internal-icon>
              </button>

              <div
                aria-labelledby="filterCollapse"
                class="filter__dropdown-menu"
                ngbDropdownMenu
              >
                <div
                  *ngIf="isTouchDevice"
                  class="mx-3 mt-3 mb-1 font-size-sm pointer-cursor"
                >
                  <span translate>
                    UI.Common.Tooltip.FilterTokenListToggleButton
                  </span>

                  <div class="d-flex mt-3">
                    <ng-container
                      *ngTemplateOutlet="innovationLabel"
                    ></ng-container>
                  </div>
                </div>

                <div class="m-3">
                  <span class="filter__label-text">{{
                    'UI.GlobalFilter.InnovationSpaceList' | translate
                  }}</span>
                </div>
                <ng-container *ngTemplateOutlet="filterItems"></ng-container>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container
          *ngTemplateOutlet="
            innovationLabel;
            context: {
              className: ''
            }
          "
        ></ng-container>
      </div>
    </ng-template>

    <ng-template #tooltip>
      <div class="filter__org-tooltip" translate>
        UI.Common.Tooltip.FilterTokenListToggleButton
      </div>
    </ng-template>

    <!--     innovation display lable block-->
    <ng-template #innovationLabel let-className="className">
      <div [ngClass]="className" class="flex-shrink-0">
        <span class="filter-applied-title">
          {{ 'UI.GlobalFilter.FilterApplied' | translate }}:</span
        >
      </div>

      <div [ngClass]="className" class="flex-1">
        <ng-container *ngIf="isLogin$ | async">
          <span
            *ngIf="isMyInvolvement"
            class="ml-1 global-filter__org-name font-weight-bold"
            translate
            >My Involvement</span
          >
          <span
            *ngIf="isMyOrgs"
            class="ml-1 global-filter__org-name font-weight-bold"
            translate
            >My Organizations</span
          >
        </ng-container>

        <span
          *ngIf="!!organizationId && orgFilterList?.length > 0"
          [ngbTooltip]="selectedOrg?.name"
          class="global-filter__org-name ml-1 font-weight-bold"
          >{{ selectedOrg?.name }}</span
        >

        <span
          *ngIf="noFilterApplied"
          class="global-filter__org-name ml-1 font-weight-bold"
          translate
          >All Innovation Spaces</span
        >
      </div>
    </ng-template>
  </div>
</div>

<ng-template #searchTemplate> </ng-template>
