<ng-container *ngTemplateOutlet="shareVenturesRef"></ng-container>

<ng-template #shareVenturesRef>
  <ng-container *ngIf="showFilter">
    <div class="shared-venture-filter">
      <div class="row">
        <div class="col-12">
          <app-search-input-wrapper
            [searchText]="sharedVenturesfilter.keyword"
            [editMode]="true"
            (resetSearchClick)="onResetSearchText()"
          >
            <form (submit)="searchVenture()" action="#">
              <span
                class="pointer-cursor search__icon-wrapper"
                (click)="searchVenture()"
              >
                <i class="search__icon fas fa-search"></i>
              </span>

              <input
                type="text"
                class="form-control mb-3 pl-5"
                placeholder="{{ 'Search' | translate }} {{
                  'Ventures' | translate
                }}"
                [(ngModel)]="sharedVenturesfilter.keyword"
                type="text"
                name="searchVentureText"
                id="searchVentureText"
              />
            </form>
          </app-search-input-wrapper>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <app-tag-field-autocomplete
            [(items)]="selectedOrgs"
            [limitItemsTo]="20"
            placeholder="{{ 'Search' | translate }} {{
              'Organization' | translate
            }}/{{ 'Challenge' | translate }}
              "
            [searchData]="searchOrg.bind(this)"
            [keyMapImg]="'logo'"
            (newTagAdded)="handleAddedOrg($event)"
            (itemsChange)="searchByOrg($event)"
          ></app-tag-field-autocomplete>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isGridView; else tableView">
    <ng-container *ngIf="isSearching; else viewModalBody">
      <div class="spinner-loading">
        <em class="fas fa-spinner fa-spin"></em>
      </div>
    </ng-container>

    <ng-template #viewModalBody>
      <div *ngIf="isShowAsChallenge" class="mb-4">
        <app-venture-stages-menu-bar
          (filterChanged)="onVenturesFilterChanged($event)"
          [currentFilter]="sharedVenturesfilter?.submissionStatus"
          [stateCount]="ventureStateCount"
        ></app-venture-stages-menu-bar>
      </div>

      <ng-container *ngIf="items?.length > 0; else noVenturesMessage">
        <div class="shared-entity">
          <div *ngFor="let item of items" class="item card container">
            <ng-container *ngIf="shouldShowItem(item)">
              <app-shared-venture-item
                [item]="item"
                [showSubmitMenu]="showSubmitMenu"
                [allowUpdateSubmissionStatus]="allowUpdateSubmissionStatus"
                (ventureDeleted)="paginateItems()"
                (afterLeaved)="afterLeaveVenture($event)"
              >
              </app-shared-venture-item>
              <div class="card-footer">
                <ul
                  class="venture__actions list-inline d-flex justify-content-start"
                >
                  <!-- Followers -->
                  <li
                    class="venture__actions-item list-inline-item mr-2 mr-md-5 white-space--no-wrap"
                  >
                    <ng-container
                      *ngIf="isLoadingFollowingsCount; else followersBtn"
                    >
                      <div class="text-center">
                        <i class="fas fa-spinner fa-spin"></i>
                      </div>
                    </ng-container>
                    <ng-template #followersBtn>
                      <a
                        appDebounceClick
                        (debounceClick)="showEntityData(item)"
                        class="d-flex"
                      >
                        <app-internal-icon
                          [className]="'filter-gray'"
                          [name]="'user-follow'"
                        ></app-internal-icon>
                        <span class="venture__actions-label font-weight-500 text-gray-600">
                          <span class="text-hide-small">{{
                            'Followers' | translate
                          }}</span>

                          <span
                            [innerHTML]="item.followerCount | parentheses"
                          ></span>
                        </span>
                      </a>
                    </ng-template>
                  </li>

                  <app-comment-entity
                    [entity]="item"
                    [entityName]="EntityName.Venture"
                    [isLoadingCommentsCount]="isLoadingCommentsCount"
                    (afterCommentModalClosed)="afterCommentModalClosed()"
                  ></app-comment-entity>

                  <!-- Submission status -->
                  <ng-container *ngIf="item.isSubmittedVenture">
                    <li class="venture__actions-item list-inline-item ml-auto">
                      <!-- View mode -->
                      <div
                        *ngIf="
                          !allowUpdateSubmissionStatus;
                          else submissionStatusEdit
                        "
                        class="d-flex align-items-center justify-content-center"
                      >
                        <label class="flag flag-submitted">
                          <span class="d-none d-md-inline-block"
                            >{{ 'Venture State' | translate }}:&nbsp;</span
                          >

                          <span>{{
                            item.currentSubmission?.submissionStatus?.value ||
                              VentureSubmissionStatus.Submitted | translate
                          }}</span>
                        </label>
                      </div>

                      <!-- Edit mode -->
                      <ng-template #submissionStatusEdit>
                        <div class="d-flex align-items-center">
                          <div
                            class="venture__label widget__label mb-0 mr-2 d-none d-md-inline-block"
                          >
                            {{ 'Venture State' | translate }}
                          </div>

                          <app-boostrap-dropdown
                            class="truncate-selection"
                            [items]="submissionStatuses"
                            [currentIndex]="
                              getSubmissionStatusIndex(
                                item.currentSubmission?.submissionStatus
                              )
                            "
                            (selectItem)="
                              selectSubmissionStatus(item.id, $event)
                            "
                          ></app-boostrap-dropdown>
                        </div>
                      </ng-template>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>

  <ng-template #tableView>
    <div *ngIf="isShowAsChallenge" class="mb-4">
      <app-venture-stages-menu-bar
        (filterChanged)="onVenturesFilterChanged($event)"
        [currentFilter]="sharedVenturesfilter?.submissionStatus"
        [stateCount]="ventureStateCount"
      ></app-venture-stages-menu-bar>
    </div>

    <ng-container *ngIf="items?.length > 0; else noVenturesMessage">
      <app-metadata-table-venture
        [lstAdditionalAttrDes]="
          allowUpdateSubmissionStatus ? [submissionStatusAttrDes] : []
        "
        [showSubmitMenu]="showSubmitMenu"
        [parentEntityId]="parentEntityId"
        [parentHttpService]="httpService"
        [isParentEntityOwner]="isParentEntityOwner"
        [entityDatas]="items"
        [isLoading]="isSearching"
        (appendSortParams)="onSort($event)"
        (reloadData)="paginateItems()"
      ></app-metadata-table-venture>
    </ng-container>
  </ng-template>

  <ng-template #noVenturesMessage>
    <span class="message-empty" translate>There are no related ventures</span>
  </ng-template>

  <app-bootstrap-pagination
    (changePage)="onPageChange($event)"
    (changePageSize)="onPageSizeChange($event)"
    (confirmSave)="onConfirmSave($event)"
    [allowPaginationWarning]="allowPaginationWarning"
    [collectionSize]="collectionSize"
    [pageSize]="sharedVenturesfilter.pageSize"
    [page]="sharedVenturesfilter.pageIndex"
    [shouldShowWarning]="areVentureStatesHaveModified"
    [showChangePageSizeCtrl]="true"
  ></app-bootstrap-pagination>

  <app-people-modal
    *ngIf="showPeopleModal"
    [entityId]="selectedEntityId"
    [entityTitle]="selectedEntity?.title"
    [modalTitle]="'Followers' | translate"
    [httpService]="ventureHttpService"
    (dismissModal)="showPeopleModal = false"
  ></app-people-modal>
</ng-template>

<ng-template #modalRef let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="shareVenturesRef"></ng-container>
  </div>
</ng-template>
