<app-loading-indicator></app-loading-indicator>

<div class="navigation-container">
  <div class="navigation">
    <div class="navigation-back-section">
      <div
        class="navigation-back-section__button"
        #backBtn
        (click)="backToPreviousPage(backBtn)"
        [class.disabled-input]="history?.length === 1"
      >
        <i class="fas fa-long-arrow-alt-left"></i>
      </div>
    </div>
    <div
      class="navigation__navbar d-flex d-lg-block justify-content-between pt-2"
    >
      <div class="nav-container w-100">
        <div class="navigation__search flex-grow-1">
          <div
            class="d-flex align-items-center justify-content-between justify-content-sm-start flex-shrink-0"
          >
            <ng-container
              *ngIf="
                selectedOrg?.imgUrl || (!selectedOrg?.imgUrl && !tenantLogo);
                else tenantLogoTemp
              "
            >
              <a
                href="/"
                appUnsavedFormCheck
                class="nav-link d-flex flex-column justify-content-lg-start align-items-center pl-0 pointer-cursor nav-link-separator-after"
              >
                <span class="nav-item__icon-container position-relative pt-1">
                  <app-internal-icon [name]="'home'" [height]="24">
                  </app-internal-icon>
                </span>
                <span [style.min-width.px]="'unset'" class="font-size-base">
                  Home
                </span></a
              >
              <div>
                <span
                  class="organization__logo d-flex col-1 mx-3 pointer-cursor"
                >
                  <span
                    (click)="
                      navigateToActiveInnovationSpaceDetail(selectedOrg?.id)
                    "
                    appUnsavedFormCheck
                  >
                    <img
                      [src]="selectedOrg?.imgUrl"
                      appDefaultPlaceholder
                      appDynamicImgUrl
                    />
                  </span>
                </span>
              </div>
            </ng-container>

            <ng-template #tenantLogoTemp>
              <div
                class="tenant-logo d-flex align-items-center logo-fix-size pointer-cursor pr-3"
              >
                <span
                  class="w-100"
                  (click)="navigateToActiveInnovationSpaceDetail()"
                  appUnsavedFormCheck
                >
                  <img
                    alt="tenant-logo"
                    [src]="tenantLogo"
                    appDefaultPlaceholder
                    appDynamicImgUrl
                  />
                </span>
              </div>
            </ng-template>
          </div>
          <a
            *ngIf="!isShowSearchBar"
            class="nav-link d-none d-sm-flex flex-sm-column justify-content-lg-start align-items-center pointer-cursor"
            (click)="toggleSearchBar()"
          >
            <span class="nav-item__icon-container position-relative pt-1">
              <i class="nav__item-icon fas fa-search"></i>
            </span>
            <span
              [style.min-width.px]="'unset'"
              class="font-size-base"
              translate
            >
              Search
            </span></a
          >

          <div
            *ngIf="isShowSearchBar"
            class="nav-global-filter__search w-100 ml-3 mr-4"
          >
            <div class="search-control">
              <i (click)="onSearch()" class="fa fa-search pointer-cursor"></i>
            </div>
            <form action="#" class="global-filter__search-form">
              <input
                type="text"
                name="query"
                class="form-control global-filter__search-input"
                placeholder="{{ 'Search' | translate }}"
                [(ngModel)]="globalFilter.searchKeyword"
                (keydown.enter)="onSearch()"
              />
            </form>

            <div class="search-control d-flex align-items-center">
              <span
                *ngIf="sessionService.isOnSearchResultPage"
                class="btn btn-link font-size-sm clear-all-btn mr-3"
                (click)="onClearText()"
                >Clear</span
              >
              <em
                (click)="toggleSearchBar()"
                class="reset-search far fa-times mr-3"
                focusable="false"
              >
              </em>
            </div>
          </div>
        </div>
        <div
          class="navigation__navs flex-shrink-0"
          [ngClass]="{ show: isNavsActivated }"
        >
          <div class="navigation__nav d-flex flex-column flex-sm-row">
            <div class="d-sm-none text-right pr-3">
              <i (click)="toggleNavs()" class="far fa-times fa-2x"></i>
            </div>
            <ul *ngIf="navs" class="navbar-nav p-lg-0">
              <ng-container *ngIf="!isShowSearchBar">
                <li
                  *ngFor="let nav of navs"
                  class="nav-item"
                  [ngClass]="{ 'dropdown dropdown-hover': nav.subMenus }"
                >
                  <app-nav-item [item]="nav"></app-nav-item>
                </li>

                <ng-container *ngIf="navDropdownItems.length > 0">
                  <div
                    ngbDropdown
                    class="nav-item nav-dropdown dropdown dropdown-hover route-active"
                  >
                    <a
                      class="nav-link flex-sm-column justify-content-lg-start align-items-center pointer-cursor d-none d-sm-flex route-active"
                    >
                      <span
                        class="nav-item__icon-container position-relative pt-1"
                      >
                        <i class="nav__item-icon fas fa-bars"></i>
                      </span>
                      <span
                        [style.min-width.px]="'unset'"
                        class="py-1 font-size-base"
                        translate
                      >
                        More <i class="fas fa-sort-down ml-1 font-size-base"></i
                      ></span>
                    </a>
                    <div ngbDropdownMenu class="nav-dropdown-items p-3">
                      <li
                        *ngFor="let nav of navDropdownItems"
                        class="nav-item p-0"
                      >
                        <app-nav-item-dropdown
                          [item]="nav"
                          [wrapperClass]="'nav-item-dropdown'"
                        ></app-nav-item-dropdown>
                      </li>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <app-nav-news-messages
                [wrapperClass]="'nav-link-separator-before'"
              ></app-nav-news-messages>
              <app-nav-profile
                class="d-flex justify-content-between align-items-center"
              ></app-nav-profile>
            </ul>

            <app-languages class="d-none d-sm-block pl-3"></app-languages>

            <div
              class="d-flex d-sm-none align-items-center mt-2 navigation__content--padding"
            >
              <span class="pr-2 pl-1 pt-2 nav-link">
                {{ 'UI.PoweredBy' | translate }}
              </span>

              <app-shared-icon
                name="logoBBV"
                width="50px"
                height="30px"
              ></app-shared-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <button
          *ngIf="sessionService.isOnListPage"
          class="navbar-toggler navigation-toggler"
          type="button"
          (click)="toggleSearch()"
        >
          <i class="fa fa-search"></i>
        </button>

        <button
          class="navbar-toggler navigation-toggler"
          type="button"
          (click)="toggleNavs()"
        >
          <i class="fa fa-bars"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div
  [ngClass]="{
    'navigation-global-filter': !isSearchActivated && !isNavsActivated
  }"
>
  <div
    [ngClass]="{
      'navigation-global-filter__container':
        !isSearchActivated && !isNavsActivated
    }"
  >
    <app-global-filter
      #globalFilter
      [showActiveInnovationSpaceImg]="false"
      [showSearchBar]="isSearchActivated ? true : false"
      (filterPanelClosed)="toggleSearch()"
      [isMobileFilterMode]="isSearchActivated"
      class="w-100"
    ></app-global-filter>
  </div>
</div>
