import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { SessionService } from '@src/app/core/session.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { OrganizationPropertyName } from '@src/app/shared/enums/property-name.enum';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { OrganizationInterface } from '@src/app/shared/interfaces/organization.interface';
import { MetadataTableComponent } from '../metadata-table.component';

@Component({
  selector: 'app-metadata-table-organization',
  templateUrl: '../metadata-table.component.html',
})
export class MetadataTableOrganizationComponent extends MetadataTableComponent<OrganizationInterface> {
  entityName = EntityName.Organization;
  orgFilterPropertyName: string[] = [
    'Id',
    'Logo',
    'OrgName',
    'EnclosingOrgName',
    'CreatedDate',
    'Image',
    OrganizationPropertyName.PartnerStatus,
    'Headline',
    'SubHeadline',
    'Description',
    'Expertises',
    'KeyOfferings',
    OrganizationPropertyName.HasMemberCard,
    OrganizationPropertyName.WhiteLableSiteName,
    'ResidentOf',
    'InnovationFocus',
    'Competency',
    'CompanyType',
    'CountryOfOrigin',
  ];
  readonlyField = ['Id', 'CreatedDate'];

  constructor(
    protected sessionService: SessionService,
    public baseHttpService: OrganizationHttpService,
    protected cd: ChangeDetectorRef
  ) {
    super(sessionService, baseHttpService, cd);
  }

  hasEditPermission(attrDes: AttributeDescription, entityId: number): boolean {
    const entity = this.entityDatas.find((x) => x.id === entityId);
    const canEdit = super.hasEditPermission(attrDes, entityId);

    return (
      canEdit && (this.isOwner(entity) || this.hasCustomPermission(attrDes))
    );
  }

  getImgCustomClass(propertyName: string): string {
    if (propertyName === 'Logo') {
      return 'organization__logo';
    } else {
      return super.getImgCustomClass(propertyName);
    }
  }

  getEditCellCustomClass(propertyName: string): string {
    if (propertyName === OrganizationPropertyName.PartnerStatus) {
      return 'position-relative w-100 h-100';
    } else {
      return super.getEditCellCustomClass(propertyName);
    }
  }

  protected getDefaultLstFilterPropertyName(): string[] {
    return this.orgFilterPropertyName;
  }

  protected customUpdateColumn(resultColumn: Record<string, any>): void {
    if (resultColumn.propertyName === OrganizationPropertyName.PartnerStatus) {
      resultColumn.width = 170;
    }
  }

  protected hasCustomPermission(attrDes: AttributeDescription): boolean {
    return (
      this.sessionService.currentUser?.isTenantAdmin &&
      attrDes.propertyName === OrganizationPropertyName.PartnerStatus
    );
  }
}
