<ul class="news-messages news-messages__list" ngxVisibilityAnchorDirective>
  <ng-container *ngFor="let item of items">
    <li
      [class]="
        item.messageType === MessageType.Message
          ? 'item-message'
          : 'news-messages__item'
      "
      class="news-messages__item"
    >
      <ng-container *ngIf="item.isLoading; else newsMessageRef">
        <div class="text-center news-messages__item--loading">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </ng-container>

      <ng-template #newsMessageRef>
        <div
          (ngxVisibility)="onVisible(item.id, $event)"
          [id]="item.id"
          [ngxVisibilityThreshold]="1"
        >
          <ng-container
            *ngIf="item.messageType === MessageType.News; else messageRef"
          >
            <app-news-item
              (afterCommentModalClosed)="syncCommentStateCount($event)"
              (contentLoaded)="restorePosition(item.id)"
              (showLikesModal)="openLikesModal(item)"
              [currentPersonId]="currentUserId"
              [news]="item"
            >
            </app-news-item>
          </ng-container>

          <ng-template #messageRef>
            <app-message-item-container
              (likesModalOpened)="openLikesModal(item)"
              (contentLoaded)="restorePosition(item.id)"
              [currentUserId]="currentUserId"
              [item]="item"
            ></app-message-item-container>
          </ng-template>
        </div>
      </ng-template>
    </li>
  </ng-container>
</ul>
