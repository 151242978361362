<div class="navigation-back-section background-white px-0">
  <div
    class="navigation-back-section__button"
    #backBtn
    (click)="backToPreviousPage(backBtn)"
    [class.disabled-input]="history?.length === 1"
  >
    <i class="fas fa-long-arrow-alt-left"></i>
  </div>
</div>
<!-- Login -->
<div
  class="jip-login"
  [class.login-wide-screen]="isSentConfirmationEmail || isAccountNotActive"
>
  <div *ngIf="logoDisplayOption === 'jip'" class="jip-login__logo text-center">
    <app-jip-logo
      class="jip-login__logo--others"
      name="jip-logo"
      width="380px"
      height="106px"
    ></app-jip-logo>
    <app-jip-logo
      class="jip-login__logo--mobile"
      name="jip-logo"
      width="380px"
      height="106px"
    ></app-jip-logo>
  </div>
  <div
    *ngIf="logoDisplayOption === 'innovation_space'"
    class="jip-login__org-logo"
  >
    <app-organization-logo
      [className]="'filter__org-logo text-center'"
      [organizationLogo]="(centralConfig?.read(globalOrgId) | async)?.logo.url"
    ></app-organization-logo>
  </div>

  <div [ngSwitch]="credentialDisplayMode">
    <!-- Login Mode -->
    <div *ngSwitchCase="'login'">
      <div
        *ngIf="isAuthenSessionExpired"
        class="mt-4 text-info jip-login__status-info"
        translate=""
      >
        UI.Login.Session-expired-message
      </div>

      <div class="jip-login__header d-flex justify-content-center mb-3">
        <ng-container *ngIf="!isAccountNotActive; else accountNotActive">
          <span class="jip-login__header-title" translate>Log in</span>
        </ng-container>

        <ng-template #accountNotActive>
          <span class="jip-login__header-title" translate
            >UI.UserNotActive.Your-account-not-active</span
          >
        </ng-template>
      </div>

      <div>
        <app-form-login
          #loginForm
          [email]="email"
          [assignToOrgId]="assignToOrgId"
          (loginSuccess)="setEventDetailPageCallback(); goBack()"
          (openAccountInactiveInformation)="onDisplayedAccountNotActive()"
          (openForgotPasswordForm)="toggleForgotPasswordForm($event)"
          [applyPendingToAutoAssignment]="applyPendingToAutoAssignment"
        ></app-form-login>
      </div>

      <div class="jip-login__header d-flex justify-content-center">
        <span class="jip-login__header-info"
          >{{ 'UI.Authentication.NoAccount' | translate }}:
          <a
            href=""
            class="jip-login__text-underline ml-2"
            (click)="toggleRegistrationForm($event)"
            >{{ 'UI.Authentication.SignUp' | translate }}</a
          >
        </span>
      </div>
    </div>

    <!-- Registration Mode -->
    <div *ngSwitchCase="'register'">
      <div class="jip-login__header d-flex flex-column align-items-center mb-3">
        <ng-container *ngIf="!isSentConfirmationEmail; else alreadyRegistered">
          <span class="jip-login__header-title mb-3" translate>Register</span>
        </ng-container>

        <ng-template #alreadyRegistered>
          <span class="jip-login__header-title" translate
            >UI.Registration.Please-confirm-email</span
          >
        </ng-template>
      </div>

      <div>
        <app-form-register
          #registerForm
          (loginSuccess)="goBack()"
          (sendConfirmationEmail)="onConfirmationEmailSent()"
          [assignToOrgId]="assignToOrgId"
          [invitationTokens]="invitationTokens"
          [termOfUseUrl]="termOfUseUrl"
          [portalName]="portalName"
          [portalUrl]="portalUrl"
          [applyPendingToAutoAssignment]="applyPendingToAutoAssignment"
        ></app-form-register>
      </div>

      <ng-container *ngIf="!isSentConfirmationEmail">
        <app-login-with-linkedin-btn
          [text]="'Register with LinkedIn'"
          [assignToOrgId]="assignToOrgId"
          [applyPendingToAutoAssignment]="applyPendingToAutoAssignment"
        ></app-login-with-linkedin-btn>
      </ng-container>

      <div
        class="jip-login__header d-flex justify-content-center"
        *ngIf="!isSentConfirmationEmail"
      >
        <span class="jip-login__header-info" translate
          >Already a member:
          <a
            href=""
            class="jip-login__text-underline"
            (click)="toggleLoginForm($event)"
            translate
            >Log in</a
          >
        </span>
      </div>
    </div>

    <!-- Forgot Password Mode -->
    <div *ngSwitchCase="'forgot-password'">
      <div class="jip-login__header d-flex justify-content-center mb-3">
        <ng-container
          *ngIf="!isPasswordResetEmailSent; else resetPasswordTittle"
        >
          <span class="jip-login__header-title" translate>ForgotPassword</span>
        </ng-container>

        <ng-template #resetPasswordTittle>
          <span class="jip-login__header-title" translate>ResetPassword</span>
        </ng-template>
      </div>

      <div>
        <app-forgot-password
          (sendConfirmationEmail)="onSentResetPasswordEmail()"
        ></app-forgot-password>
      </div>

      <div
        class="jip-login__header d-flex justify-content-center"
        *ngIf="!isSentConfirmationEmail"
      >
        <span class="jip-login__header-info" translate
          >UI.Forgot_Password.Back-to
          <a
            href=""
            class="jip-login__text-underline"
            (click)="toggleLoginForm($event)"
            translate
            >Log in</a
          >
        </span>
      </div>
    </div>
  </div>
</div>
