<div class="modal-header">
  <button
    (click)="activeModal.dismiss(false)"
    aria-describedby="modal-title"
    aria-label="Close"
    class="btn-close close font-size-2xl"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <ngx-file-drop
    (onFileDrop)="dropped($event)"
    [accept]="acceptType"
    [hidden]="imageFile"
    [multiple]="false"
    contentClassName="content"
    dropZoneClassName="file-drop"
  >
    <ng-template
      let-openFileSelector="openFileSelector"
      ngx-file-drop-content-tmp
    >
      <div class="d-flex flex-column align-items-center">
        <i class="img-icon fas fa-images fa-10x mb-4"></i>

        <div class="font-size-xl p-4 text-center" translate>
          UI.Common.UploadFileLabel.Prefix
          <span
            (click)="openFileSelector()"
            class="text-underline"
            translate
            type="button"
          >
            UI.Common.UploadFileLabel.Link</span
          >, {{ 'UI.Common.UploadFileLabel.Suffix' | translate }}
        </div>
      </div>
    </ng-template>
  </ngx-file-drop>

  <angular-cropper
    *ngIf="imageFile"
    #angularCropper
    [imageUrl]="imageUrl"
    [cropperOptions]="cropperOptions"
  >
  </angular-cropper>

  <app-range-slider
    *ngIf="imageFile"
    (ngModelChange)="onZoom($event)"
    [(ngModel)]="scale"
  ></app-range-slider>

  <div class="modal-footer d-flex justify-content-center mt-3">
    <button
      *ngIf="imageFile"
      (click)="clearImg()"
      class="mb-0 btn btn-outline-primary"
      translate
    >
      Cancel
    </button>

    <button
      *ngIf="imageFile"
      (click)="crop()"
      class="mb-0 ml-4 btn btn-primary"
      translate
    >
      UI.Common.OK
    </button>
  </div>
</div>
