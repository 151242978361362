import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { AuthenService } from '@core/authen/authen.service';
import { UnsavedFormCheckService } from '@core/form/unsaved-form-check.service';
import { BaseHttpService } from '@core/http/base-http.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TagFieldExpandedComponent } from '@shared/components/molecules/tag-field-expanded/tag-field-expanded.component';
import { AttributePropertyName } from '@shared/enums/attribute-display-name.enum';
import { GlobalEventBus } from '@shared/enums/event-bus.enum';
import { HttpStatusCode } from '@shared/enums/httpstatuscode.enum';
import { RecipientType } from '@shared/enums/message.enum';
import { InfiniteScrollDataAdapter } from '@shared/helpers/infinite-scroll-data.adapter';
import {
  CustomAtrributeValue,
  MetaInformation,
} from '@shared/interfaces/attribute-description.interface';
import { Entity } from '@shared/models/entity.model';
import { SendMessageBoxBaseComponent } from '@src/app/components/dialogs/send-message-box-base/send-message-box-base.component';
import {
  MessageRecipient,
  SendMessageHttpService,
} from '@src/app/core/http/send-messages-http.service';
import { ToastService } from '@src/app/core/toast.service';
import {
  TagFieldCustom,
  TagFieldType,
  transformToTagModal,
} from '@src/app/shared/components/tag-field/tag-field.component';
import { SUGGESTIONS } from '@src/app/shared/constants/common';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import {
  CustomMessageInterface,
  MessagePayload,
} from '@src/app/shared/interfaces/news-message.interface';
import { ApiResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { TextValidator } from '@src/app/shared/utils/form-fields-validation';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { EventBusService } from 'ngx-eventbus';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

export enum CommunityType {
  AdminOnly,
  AdminAndMember,
}

@Component({
  selector: 'app-new-message-dialog',
  templateUrl: './new-message-dialog.component.html',
})
export class NewMessageDialogComponent
  extends SendMessageBoxBaseComponent
  implements OnInit
{
  @Input() showProfile = true;
  @Input() placeholder = 'UI.SendMessage.Placeholder';
  @Input() title = 'NewMessage';
  @Input() description = 'UI.New-Message.Select-Order-Hint';
  @Input() defaultContent = '';
  @Input() hasContent = false;

  // For handle directly payload -> Separate Using
  @Input() messagePayload: MessagePayload = null;

  // For handle share entity to group people -> Separate Using
  @Input() customMessage: CustomMessageInterface = null;

  @Input() shouldAddNewItemToList = true;

  @Input() choice = AttributePropertyName.Recipient;

  @Input() excludedGroup = [RecipientType.ShareMessageChoicesEverybody];

  // For shared to specific entity
  @Input() entity: Entity;

  @Input() httpService: BaseHttpService<any>;

  @Input() allowImgOnRTE = true;

  @ViewChild('messageInput') messageInputTag: any;

  @ViewChild(TagFieldExpandedComponent)
  tagFieldExpanded: TagFieldExpandedComponent;

  profile: UserInterface;

  items: CustomAtrributeValue[] = [];

  selectedRecipients: TagFieldCustom[] = [];

  isSubmitted?: boolean;
  showEmailCheckbox = false;
  showIncludeSubOrgCheckbox = false;

  searchData$: InfiniteScrollDataAdapter;

  shouldLoadSearchData = true;

  shouldShowAdminOnlyCheckbox = false;
  communityTypeEnum = CommunityType;
  searchKey = '';

  //#region SERVICES
  protected toastService: ToastService;
  private translateService: TranslateService;

  //#endregion End Services

  constructor(
    public activeModal: NgbActiveModal,
    public unsavedFormCheckService: UnsavedFormCheckService,
    protected injector: Injector,
    protected authenService: AuthenService,
    protected sendMessageHttpService: SendMessageHttpService,
    private eventBus: EventBusService
  ) {
    super(
      activeModal,
      unsavedFormCheckService,
      authenService,
      sendMessageHttpService,
      injector
    );
    this.toastService = injector.get<ToastService>(ToastService);
    this.translateService = injector.get<TranslateService>(TranslateService);
  }

  get currentGroup(): CustomAtrributeValue {
    return this.form.value?.currentGroup;
  }

  get selectedRecipientsControl(): AbstractControl {
    return this.form?.get('selectedRecipients');
  }

  get currentGroupControl(): AbstractControl {
    return this.form?.get('currentGroup');
  }

  get communityTypeControl(): AbstractControl {
    return this.form?.get('communityType');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.searchData$ = new InfiniteScrollDataAdapter(this.getSource(), 20);
  }

  isFormNotSaved(): boolean {
    return this.selectedRecipients.length > 0 || this.content?.value;
  }

  initForm(): void {
    this.form = this.fb.group({
      selectedRecipients: [null, [Validators.required]],
      currentGroup: [],
      Content: [this.defaultContent],
      isSendMail: [false],
      isIncludeSubOrganization: [false],
      imageId: [],
      communityType: [this.communityTypeEnum.AdminOnly],
      sendToAdminOfPartnerOrg: [false],
      sendToAdminAndMemberOfPartnerOrg: [false],
    });
    if (this.contentAttributeDescription?.required) {
      this.content?.setValidators([
        Validators.required,
        TextValidator.notEmptyOrWhitespace,
      ]);
    }
    this.formErrorService.register(this);

    this.currentGroupControl?.valueChanges.subscribe(() => {
      this.toggleControlRequiredValidator(
        this.currentGroup?.codeId === RecipientType.ShareMessageChoicesEverybody
      );

      this.shouldLoadSearchData = !this.excludedGroup?.includes(
        this.currentGroup?.codeId
      );
    });

    this.communityTypeControl?.valueChanges.subscribe(() => {
      this.form.patchValue({
        sendToAdminOfPartnerOrg: false,
        sendToAdminAndMemberOfPartnerOrg: false,
      });
    });
  }

  getSource(): any {
    return (
      searchKey: string,
      pageIndex: number,
      pageSize: number
    ): Observable<any> => {
      return of({}).pipe(
        switchMap(() => this.searchEntities(searchKey, pageIndex, pageSize))
      );
    };
  }

  searchEntities(
    text: string,
    pageIndex?,
    pageSize?
  ): Observable<TagFieldCustom[]> {
    this.resetErrorStage();
    if (!this.currentGroup) {
      return of([]);
    }
    return this.sendMessageHttpService
      .searchRecipients(this.currentGroup, text, pageIndex, pageSize)
      .pipe(
        map((res: MessageRecipient) => {
          const preselections = transformToTagModal(
            res.preselections,
            TagFieldType.PreSelection
          );

          const suggestions: TagFieldCustom = {
            display: this.translateService.instant(SUGGESTIONS),
            type: TagFieldType.Header,
            value: SUGGESTIONS,
          };
          preselections.push(suggestions);

          const items = transformToTagModal(res.items);
          return pageIndex ? items : preselections.concat(...items);
        })
      );
  }

  handleRecipientsChanged(items: TagFieldCustom[]): void {
    this.resetErrorStage();
    this.setRecipients(items);
  }

  /**
   * Clear current items if new type is selected -> current items = [{new item}]
   */
  handleRecipientAdded(item: TagFieldCustom): void {
    this.resetErrorStage();

    if (this.selectedRecipients && this.selectedRecipients.length > 0) {
      if (item.type === TagFieldType.Header) {
        this.setRecipients([]);
        return;
      }
      if (
        item.type === TagFieldType.PreSelection ||
        item.type !== this.selectedRecipients[0].type
      ) {
        this.setRecipients([item]);
      }
    }
  }

  handleGroupChanged(group: CustomAtrributeValue): void {
    this.resetErrorStage();

    this.setCurrentGroup(group);
    this.setRecipients([]);

    this.shouldLoadSearchData = !this.excludedGroup.includes(group.codeId);
    this.tagFieldExpanded?.resetInputText(this.shouldLoadSearchData);
  }

  send(): void {
    this.isSubmitting = true;
    const isSharedToEveryone =
      this.currentGroup?.codeId === RecipientType.ShareMessageChoicesEverybody;

    if (isSharedToEveryone) {
      this.sharedToEveryBody();

      return;
    }

    this.sendToSpecificGroup();
  }

  sendToSpecificGroup(): void {
    let payload: any = {};
    if (this.messagePayload === null) {
      payload = this.getPayload();
    } else {
      payload = this.getMessagePayload();
    }

    this.sendMessageHttpService
      .sendToRecipients(payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: any) => {
          const error = res as ApiResponse;
          if (error && error.status === 400) {
            console.log(error);
          }

          if (!isNaN(res) && res > 0) {
            this.sendMessageHttpService
              .read(res)
              .pipe(untilDestroyed(this))
              .subscribe((item) => {
                this.shouldAddNewItemToList &&
                  this.eventBus.triggerEvent(
                    GlobalEventBus.AddNewItemToList,
                    item
                  );
              });
            this.activeModal.close(true);
            this.showToast(true);
          } else {
            this.formErrorService.handleError(res);
            this.showToast(false);
          }
        },
        (err) => {
          this.formErrorService.handleError(err);
          this.showToast(false);
        }
      );
  }

  sharedToEveryBody(): void {
    this.httpService
      .share(this.entity.id, this.content?.value)
      .pipe(
        catchError((error) => {
          console.log('error when share entity: ', error);
          return of(undefined);
        }),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        const error = data as ApiResponse;

        if (error?.status === HttpStatusCode.BadRequest) {
          console.log('error when share entity: ', error);
        } else if (data) {
          this.toastService.showSuccess('UI.Toast.ShareSuccessfully');
          this.isSubmitting = true;
          this.activeModal.close(true);
        }
      });
  }

  getPayload(): any {
    const itemIds = this.getSelectedItemIds();

    let preselection;
    let ids;

    if (this.isPreSelection()) {
      preselection = itemIds[0];
    } else {
      ids = itemIds;
    }

    const payload = {
      type: this.currentGroup?.codeId,
      content: this.content?.value,
      isSendMail: this.isSendMailPayload(),
      includeSubOrganization: this.isIncludeSubOrgPayload(),
      preselection,
      ids,
      imageId: this.imageId?.value,
      isAdminOnly:
        this.form.value?.communityType === this.communityTypeEnum.AdminOnly
          ? true
          : false,
    };

    if (this.customMessage) {
      const customMessage = {
        entityName: this.customMessage.entityName,
        entityId: this.customMessage.entityId,
        action: this.customMessage.action,
      };
      const appendCustomMessage = 'customMessage';
      payload[appendCustomMessage] = customMessage;
    }

    return payload;
  }

  getMessagePayload(): any {
    return {
      type: this.messagePayload.type,
      content: this.content?.value,
      ids: this.messagePayload.ids,
      isSendMail: this.isSendMailPayload(),
      includeSubOrganization: false,
      imageId: this.imageId?.value,
    };
  }

  getSelectedItemIds(): number[] {
    return this.selectedRecipients.map((item) => item.value);
  }

  isPreSelection(): boolean {
    return this.selectedRecipients[0]?.type === TagFieldType.PreSelection;
  }

  resetErrorStage(): void {
    this.errorMessage = {};
    this.isSubmitting = false;
    this.isSubmitted = false;
  }

  showToast(success: boolean, message = null): void {
    const successMessage = 'UI.Toast.MessageSuccessfullySent';
    const failedMessage =
      (this.errorMessage && this.errorMessage[this.formErrorKey]) ||
      'UI.Toast.MessageFailedSent';

    if (success) {
      this.toastService.showSuccess(message || successMessage);
      this.close();
    } else {
      this.toastService.showError(message || failedMessage);
      this.isSubmitting = false;
    }
  }

  setCurrentGroup(currentGroup: CustomAtrributeValue): void {
    this.form.patchValue({ currentGroup });
    this.updateEmailFlag();
    this.updateShowToSubOrgFlag();
  }

  setRecipients(selectedRecipients: TagFieldCustom[]): void {
    this.selectedRecipients = selectedRecipients || [];
    this.form.patchValue({ selectedRecipients });
    this.updateEmailFlag();
    this.updateShowToSubOrgFlag();
    this.updateAdminOnlyFlag();
  }

  updateEmailFlag(): void {
    this.showEmailCheckbox = this.willShowEmailCheckbox();
  }

  updateShowToSubOrgFlag(): void {
    this.showIncludeSubOrgCheckbox = this.willShowIncludeToSubOrgCheckbox();
  }

  updateAdminOnlyFlag(): void {
    this.shouldShowAdminOnlyCheckbox = this.willShowAdminOnlyCheckbox();

    if (!this.shouldShowAdminOnlyCheckbox) {
      this.form.patchValue({
        communityType: this.communityTypeEnum.AdminAndMember,
      });
    } else {
      this.form.patchValue({
        communityType: this.communityTypeEnum.AdminOnly,
      });
    }
  }

  willShowEmailCheckbox(): boolean {
    return (
      this.currentGroup?.codeId !== RecipientType.ShareMessageChoicesEverybody
    );
  }

  willShowIncludeToSubOrgCheckbox(): boolean {
    if (this.customMessage !== null || this.hasNoSelectedRecipients()) {
      return false;
    }
    return this.selectedRecipients.some((item) => {
      return item.hasSubOrganization;
    });
  }

  willShowAdminOnlyCheckbox(): boolean {
    if (this.customMessage !== null || this.hasNoSelectedRecipients()) {
      return false;
    }
    return this.selectedRecipients.some((item) => {
      return item.isAllowToSendEmail;
    });
  }

  isSendMailPayload(): boolean {
    return !!this.form.value?.isSendMail;
  }

  isIncludeSubOrgPayload(): boolean {
    return (
      this.willShowIncludeToSubOrgCheckbox() &&
      (this.form.value?.sendToAdminOfPartnerOrg ||
        this.form.value?.sendToAdminAndMemberOfPartnerOrg)
    );
  }

  protected handleAfterLoadProfileData(userProfile: UserInterface): void {
    super.handleAfterLoadProfileData(userProfile);
    this.profile = userProfile;
  }

  protected handleAfterLoadAttributeDescription(res: MetaInformation): void {
    if (this.messagePayload !== null) {
      this.runMessagePayloadFlow();
      return;
    }

    this.initForm();

    const recipientAttribute = FormUtils.getFieldOptions(
      res.entityDescription.attributeDescriptions,
      this.choice
    );
    this.items = recipientAttribute?.choice.selections || [];

    this.setCurrentGroup(this.items[0]);
  }

  private runMessagePayloadFlow(): void {
    this.form = this.fb.group({
      Content: [''],
      isSendMail: [false],
    });
    if (this.contentAttributeDescription?.required) {
      this.content?.setValidators([
        Validators.required,
        TextValidator.notEmptyOrWhitespace,
      ]);
    }
    this.formErrorService.register(this);
    this.updateEmailFlag();
  }

  private hasNoSelectedRecipients(): boolean {
    return !this.selectedRecipients || this.selectedRecipients.length === 0;
  }

  private toggleControlRequiredValidator(required: boolean): void {
    if (required) {
      this.selectedRecipientsControl.setValidators(Validators.required);
    } else {
      this.selectedRecipientsControl.clearValidators();
    }

    this.selectedRecipientsControl.updateValueAndValidity();
  }
}
