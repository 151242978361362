import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-tag-field',
  templateUrl: './tag-field.component.html',
})
export class TagFieldComponent implements OnChanges {
  @Input() editable = true;
  @Input() items: TagFieldCustom[] = [];
  @Input() placeholder: string;

  @Output() itemsChange = new EventEmitter<TagFieldCustom[]>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items || changes.editable) {
      this.updateItemsStatus();
    }
  }

  updateItemsStatus(): void {
    if (this.items && this.items.length > 0) {
      for (const item of this.items) {
        item.readonly = !this.editable;
      }
    }
  }
  emitValue(event?: TagFieldCustom[]): void {
    this.itemsChange.emit(this.items);
  }
}

export interface TagFieldCustom {
  id?: number;
  codeId?: string;
  display: string;
  value: any;
  image?: string;
  readonly?: boolean;
  isAllowToSendEmail?: boolean;
  hasSubOrganization?: boolean;
  type?: TagFieldType;
}

export enum TagFieldType {
  Normal,
  PreSelection,
  Header,
}

export function transformToTagModal(
  items: any[],
  type: TagFieldType = TagFieldType.Normal
): TagFieldCustom[] {
  if (items && Array.isArray(items)) {
    return items.map((item) => {
      return {
        ...item,
        display: item.name || item.value,
        value: item.id || item.codeId,
        image: item.image,
        type,
      };
    });
  } else {
    return [];
  }
}

export function transformToTagModaByKey(
  items: (string | object)[],
  readonlyKey: boolean,
  displayKey: string = null,
  valueKey: string = null
): TagFieldCustom[] {
  if (items && Array.isArray(items)) {
    return items.map((item) => {
      return {
        display: item && displayKey ? item[displayKey] : item,
        value: item && displayKey ? item[valueKey] : item,
        readonly: readonlyKey,
      };
    });
  } else {
    return [];
  }
}
