import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { UrlParam } from '@shared/enums/url-param.enum';
import { WINDOW } from '@shared/helpers/window.token';
import { StringUtils } from '@shared/utils/string-utils';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login-with-linkedin-btn',
  templateUrl: './login-with-linkedin-btn.component.html',
})
export class LoginWithLinkedinBtnComponent implements OnInit {
  @Input() text = 'Login with LinkedIn';

  @Input() redirectParam: { key: UrlParam; value: string | number };

  @Input() assignToOrgId: number;
  @Input() applyPendingToAutoAssignment: boolean;

  @Output() onClicked = new EventEmitter();

  tenantName = '';

  constructor(
    @Inject(WINDOW) private window: Window,
    private sessionService: SessionService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.tenantName = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
  }

  redirectToLinkedInLoginPage(): void {
    const { origin } = this.window.location;
    const {
      jipUrl: { processToken },
      linkedinConfig: { pathName, clientId },
    } = environment;
    const redirectUri = new URL(
      `${origin}${this.sessionService.appendLanguagePath(processToken)}`
    );

    if (this.redirectParam) {
      const param = new URLSearchParams();
      param.append(this.redirectParam.key, this.redirectParam.value.toString());

      redirectUri.search = param.toString();
    }

    if (this.assignToOrgId) {
      const param = new URLSearchParams();

      param.append(
        UrlParam.AutoAssignment,
        `${this.assignToOrgId}-${this.applyPendingToAutoAssignment ? 1 : 0}`
      );
      redirectUri.search = param.toString();
    }

    this.window.location.href = StringUtils.generateLinkedinLoginLink(
      pathName,
      clientId,
      redirectUri.toString()
    );
  }

  emitEvent(): void {
    this.onClicked.emit();
  }
}
