import { Component, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '@components/base/base-widget/base-widget.component';
import { SearchEntitiesService } from '@components/search-results/services/search-entities.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { EntityName } from '@shared/enums/entity-name.enum';
import { PeopleInterface } from '@shared/interfaces/people.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { PageSizeConfig } from '@shared/models/pagination.model';
import { PeopleHttpService } from '@src/app/core/http/people-http.service';
import { Observable } from 'rxjs';
import { filter, share, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-people-tiles',
  templateUrl: './people-tiles.component.html',
  providers: [SearchEntitiesService],
})
export class PeopleTilesComponent
  extends BaseWidgetComponent
  implements OnInit
{
  data$: Observable<ApiGetResponse<PeopleInterface>>;
  isLogin = this.sessionService.isLogin;

  readonly entityPath = environment.jipUrl.people;

  readonly avatar = environment.wordpressJipImages + 'avatar-square.png';

  isLoading = false;

  skeletonCount = [];

  constructor(
    public readonly searchEntitiesService: SearchEntitiesService,
    protected readonly sessionService: SessionService,
    private peopleHttpService: PeopleHttpService
  ) {
    super(sessionService);

    this.data$ = this.searchEntitiesService.entities$.pipe(
      filter((arr: any) => arr.length),
      switchMap((res) => res[0].content),
      share()
    ) as Observable<ApiGetResponse<PeopleInterface>>;

    this.searchEntitiesService.isSearchLoading$.subscribe((loading) => {
      this.isLoading = loading;
    });
  }

  ngOnInit(): void {
    this.configSearchService();
    this.sessionService.isOnListPage = true;
    this.skeletonCount = [...Array(Number(this.columns))];
  }

  private configSearchService(): void {
    const widgetPageSize =
      Number(this.page_size) || PageSizeConfig.ThreeItemsFirstPage;

    this.searchEntitiesService.entities = [{ key: EntityName.Person }];
    this.searchEntitiesService.currentFilter = EntityName.Person;
    this.searchEntitiesService.pageSizeMedium = widgetPageSize;

    // Payload
    const { filterDTO } = this.searchEntitiesService;
    const keepExternalFilter = !this.org_id && this.isHeaderVisible;

    filterDTO.pageSize = widgetPageSize;
    filterDTO.organizationIds = this.org_id ? [Number(this.org_id)] : [];

    this.searchEntitiesService.generateSearchResults(keepExternalFilter).then();
  }

  getVcard(person: PeopleInterface): Observable<string> {
    return this.peopleHttpService.getVCard(person.id);
  }
}
