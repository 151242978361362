import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { EntityName, MessageType } from '@shared/enums/entity-name.enum';
import { WINDOW } from '@shared/helpers/window.token';
import { NewsMessageInterface } from '@shared/interfaces/news-message.interface';
import { ScrollingHandlerService } from '@shared/services/scrolling-handler.service';
import { isBackForwardNavigation } from '@shared/utils/common.utils';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';

@Component({
  selector: 'app-news-and-message-list',
  templateUrl: './news-and-message-list.component.html',
  styles: [],
})
export class NewsAndMessageListComponent {
  @Input() items: NewsMessageInterface[];

  @Input() uiTemplateConfig: TemplateName = TemplateName.Default;

  @Input() currentUserId: number;

  @Output() likesModalOpened = new EventEmitter<NewsMessageInterface>();

  @Output() commentModalClosed = new EventEmitter<NewsMessageInterface>();

  MessageType = MessageType;

  EntityName = EntityName;

  constructor(
    @Inject(WINDOW) private window: Window,
    private scrollingHandlerService: ScrollingHandlerService
  ) {}

  restorePosition(id: number): void {
    if (this.shouldRestorePosition(id)) {
      this.scrollingHandlerService.restorePosition(EntityName.News, id);
    } else {
      this.scrollingHandlerService.isRestorationComplete = true;
    }
  }

  openLikesModal(item: NewsMessageInterface): void {
    this.likesModalOpened.emit(item);
  }

  syncCommentStateCount(news: NewsMessageInterface): void {
    this.commentModalClosed.emit(news);
  }

  onVisible(item: number, isVisible: boolean): void {
    this.scrollingHandlerService.saveItem(
      EntityName.News,
      isVisible,
      item.toString()
    );
  }

  private shouldRestorePosition(id: number): boolean {
    return this.items[0].id !== id && isBackForwardNavigation(this.window);
  }
}
