import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { BaseEntityInterface } from '@src/app/shared/interfaces/base/base-entity.interface';
import { JoinState } from '@src/app/shared/interfaces/join.interface';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-join-interact',
  template: '',
})
export class JoinInteractComponent<T extends BaseEntityInterface>
  implements OnDestroy
{
  @Input() baseHttpService: BaseHttpService<T>;
  @Input() entity: T;
  @Input() entityName: string;
  @Input() isOwner: boolean;

  @Output() afterJoined = new EventEmitter();

  isSyncLatestJoinState = false;

  constructor(
    public readonly sessionService: SessionService,
    protected readonly toastService: ToastService
  ) {}

  join(event: Event, entity: T): void {
    event.preventDefault();

    if (
      !this.sessionService.isLogin ||
      !entity ||
      entity.hasJoined ||
      !entity.id
    ) {
      return;
    }
    this.isSyncLatestJoinState = true;
    this.baseHttpService
      .join(entity.id, {})
      .pipe(
        finalize(() => {
          this.afterJoined.emit();
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.syncLatestJoinState(entity.id);
        },
        () => {
          this.isSyncLatestJoinState = false;
          this.toastService.showError('UI.Toast.UpdatedFailed');
        }
      );
  }

  protected syncLatestJoinState(entityId: number): void {
    const stringIds = [entityId.toString()];

    this.baseHttpService
      .getJoinStates(stringIds)
      .pipe(
        finalize(() => {
          this.isSyncLatestJoinState = false;
        }),
        untilDestroyed(this)
      )
      .subscribe((joinStates: JoinState[]) => {
        this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
        const foundState = joinStates.find(
          (state) => state.entityId === entityId
        );
        this.entity.hasJoined = foundState ? foundState.hasJoined : false;
        this.entity.canJoin = foundState ? foundState.canJoin : false;
        this.entity.isPending = foundState ? foundState.isPending : false;
      });
  }

  ngOnDestroy(): void {
    /**/
  }
}
