import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomFormService } from '@core/form/custom-form.service';
import { BaseHttpService } from '@core/http/base-http.service';
import { I18nService } from '@core/i18n/i18n.service';
import { PatternType } from '@shared/enums/attribute-type.enum';
import {
  AttributeDescription,
  AttributeValue,
} from '@shared/interfaces/attribute-description.interface';
import { LanguageInterface } from '@shared/interfaces/language.interface';
import { MetadataRecord } from '@shared/interfaces/metadata-table.interface';
import { Entity } from '@shared/models/entity.model';
import {
  CustomMetadataUtils,
  ORG_SPECIFIC_INFO_SUPPORT_ATTRIBUTE_TYPE,
} from '@shared/utils/custom-metadata-utils';
import { StringUtils } from '@shared/utils/string-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MultilingualFormDataManagementService {
  baseHttpService: BaseHttpService<unknown>;

  entityId: number;

  customMetadata: MetadataRecord = {
    attributeDescriptions: [],
    attributeValues: [],
  };

  // Map<locale, FormGroup> store corresponding form instance by locale
  forms = new Map<
    string,
    { form: UntypedFormGroup; entity: unknown; customMetadata: MetadataRecord }
  >();

  // Map<sectionIndex, locale> store corresponding locale by form section index
  sectionsLanguage = new Map<number | string, string[]>();

  constructor(
    private readonly i18nService: I18nService,
    private readonly customFormService: CustomFormService,
    private readonly fb: UntypedFormBuilder
  ) {}

  get defaultLanguage(): LanguageInterface {
    return this.i18nService.language;
  }

  initializeFormGroup(form: UntypedFormGroup, entity: unknown): void {
    if (entity) {
      const { attributeDescriptions, attributeValues } = entity as Entity;
      this.handleCustomMetadata(attributeDescriptions, attributeValues);
    }

    this.forms.set(this.defaultLanguage?.locale, {
      form,
      entity,
      customMetadata: this.customMetadata,
    });
  }

  /**
   * Switching language
   * @param language language switching to
   * @param index section index
   */
  switchLanguage(language: LanguageInterface, index: number | string): void {
    if (!this.forms.has(language.locale)) {
      // Create new FormGroup instance by locale
      const controls = this.customFormService.generateControlsConfig([]);
      const formGroup = {
        Content: [],
        Image: ['', [Validators.required]],
        ...controls,
      };

      const newForm = this.fb.group(formGroup);

      // Store to form group
      this.getEntityLanguageInstance(this.entityId, language.locale).subscribe(
        (entity) => {
          this.updateFormValue(newForm, entity, language.locale);
          this.handleCustomMetadata(
            entity.attributeDescriptions,
            entity.attributeValues
          );
          this.forms.set(language.locale, {
            form: newForm,
            entity,
            customMetadata: this.customMetadata,
          });
        }
      );
    }

    // store form section
    const previousValue = this.sectionsLanguage.get(index);
    const newValue = previousValue
      ? Array.from(new Set([language.locale, ...previousValue]))
      : [language.locale];

    this.sectionsLanguage.set(index, newValue);
  }

  removeLanguage(index: number | string, locale: string): void {
    if (locale) {
      this.forms.delete(locale);
      const previousLocales = this.sectionsLanguage.get(index);
      const removedLocales = previousLocales.filter((lc) => lc !== locale);
      this.sectionsLanguage.set(index, removedLocales);

      return;
    }

    this.sectionsLanguage.delete(index);
  }

  private getEntityLanguageInstance(
    entityId: number,
    locale: string
  ): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 'jip-locale': locale });

    return this.baseHttpService.read(entityId, headers);
  }

  private updateFormValue(
    form: UntypedFormGroup,
    entity: unknown,
    locale: string
  ): void {
    if (!this.customFormService.entityDescription) {
      return;
    }

    const { attributeDescriptions, attributeValues } = entity as Entity;
    this.handleCustomMetadata(attributeDescriptions, attributeValues);

    const customMetadata = this.customMetadata;

    for (const attr of this.customFormService.entityDescription
      .attributeDescriptions) {
      const key = attr.propertyName;
      this.updateFieldValue(form, key, entity, customMetadata);
    }

    if (this.customFormService.customAttributeDescriptions?.length > 0) {
      for (const attr of this.customFormService.customAttributeDescriptions) {
        const key = attr.propertyName;
        this.updateFieldValue(form, key, entity, customMetadata);
      }
    }
  }

  private updateFieldValue(
    form: UntypedFormGroup,
    key: string,
    entity: unknown,
    customMetadata: MetadataRecord
  ): void {
    form.patchValue(
      {
        [key]: this.getFormFieldValueFromEntity(entity, key, customMetadata),
      },
      {
        emitEvent: false,
      }
    );
  }

  private getFormFieldValueFromEntity(
    entity: unknown,
    name: string,
    customMetadata: MetadataRecord
  ): any {
    const entityValue = entity[StringUtils.toLowerCaseFirstLetter(name)];
    if (entityValue !== undefined) {
      return entityValue;
    }

    // Handle for custom metadata record
    if (this.customMetadata.attributeDescriptions?.length > 0) {
      return CustomMetadataUtils.getAttributeValueByPropertyName(
        customMetadata.attributeDescriptions,
        customMetadata.attributeValues,
        name
      );
    }
  }

  handleCustomMetadata(
    attributeDescriptions: AttributeDescription[] = [],
    attributeValues: AttributeValue[] = []
  ): void {
    if (attributeDescriptions?.length > 0) {
      const filterType = [PatternType.Dedicated];

      this.customMetadata = CustomMetadataUtils.filterMetadataRecord(
        attributeDescriptions,
        attributeValues,
        filterType,
        ORG_SPECIFIC_INFO_SUPPORT_ATTRIBUTE_TYPE
      );
    }
  }
}
