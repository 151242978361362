<ul
  *ngIf="languages"
  class="header-languages navbar-nav d-flex justify-content-between"
>
  <li class="header-languages__item header-languages__title font-weight-bold">
    <span translate>UI.Common.Language</span>:
  </li>
  <li
    class="nav-item pl-0 text-right"
    [ngClass]="{ 'dropdown dropdown-hover': showAsDropdown }"
  >
    <span
      *ngIf="showAsDropdown && currentLanguage"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      class="header-languages__label header-languages__label--active dropdown-hover__trigger dropdown-toggle"
    >
      {{ currentLanguage.code | translate }}
      <i class="fas fa-sort-down ml-1"></i>
    </span>
    <ul
      class="language-items"
      [ngClass]="
        showAsDropdown
          ? 'dropdown-menu dropdown-menu-left dropdown'
          : 'list-inline two-items'
      "
      aria-labelledby="dropdownMenuButton"
    >
      <ng-container *ngTemplateOutlet="allLanguages"></ng-container>
    </ul>
  </li>
</ul>

<ng-template #allLanguages>
  <!-- //NOSONAR -->
  <li
    *ngFor="let language of languages"
    class="header-languages__item list-inline-item"
    [ngClass]="{ 'dropdown-item': showAsDropdown }"
    (click)="languageCode.click()"
  >
    <a
      #languageCode
      class="header-languages__link"
      [ngClass]="{ 'link-downdown': showAsDropdown }"
      (click)="$event.stopPropagation()"
      (clickAfterChecking)="setLanguage(language)"
      [destinationHref]="getDestination(language)"
      appUnsavedFormCheck
    >
      <span
        class="header-languages__label"
        [ngClass]="{
          'header-languages__label--active':
            language.code === currentLanguage.code
        }"
      >
        {{ language.code | translate }}
      </span>
    </a>
  </li>
</ng-template>
