import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@env/environment';
import { EventUtils } from '@shared/utils/event-utils';
import { FormUtils } from '@shared/utils/form-utils';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import { EventState } from '@src/app/shared/enums/event-entity.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { TimeUtils } from '@src/app/shared/utils/time-utils';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { JoinInteractComponent } from '../join-interact.component';

@Component({
  selector: 'app-join-event',
  templateUrl: './join-event.component.html',
})
export class JoinEventComponent extends JoinInteractComponent<EventInterface> {
  @Input() btnStyle: 'minimal' | 'standard' = 'standard';

  @Output() afterEventPassed = new EventEmitter();
  @Output() leaveEntity = new EventEmitter<boolean>();

  EventState = EventState;

  constructor(
    public readonly sessionService: SessionService,
    protected readonly toastService: ToastService
  ) {
    super(sessionService, toastService);
  }

  leave(isLeaveSuccess: boolean): void {
    this.leaveEntity.emit(isLeaveSuccess);
  }

  join(event: Event, entity: EventInterface): void {
    event.preventDefault();

    if (!entity) return;

    if (EventUtils.isPassedEvent(entity)) {
      this.afterEventPassed.emit();
      return;
    }

    if (!this.sessionService.isLogin) {
      this.goToEventRegister(entity.id);

      return;
    }

    this.isSyncLatestJoinState = true;
    // Get time zone of target date to handle Daylight Saving Time
    const targetDateTimeZoneOffset = TimeUtils.getTargetDateTimezone(
      entity.eventDateTime
    );
    this.baseHttpService
      .join(entity.id, {
        targetTimezone: targetDateTimeZoneOffset,
      })
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isSyncLatestJoinState = false)),
        catchError((err: HttpErrorResponse) => {
          if (err.error.title) {
            this.toastService.showError(err.error.title);
          }
          return of(undefined);
        })
      )
      .subscribe((res) => {
        if (res !== undefined) {
          this.afterJoined.emit();
        }
      });
  }

  goToEventRegister(id: number): void {
    FormUtils.navigateTo(
      this.sessionService.appendLanguagePath(
        `${environment.jipUrl.eventRegister}/${id}`
      )
    );
  }
}
