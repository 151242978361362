<app-unsaved-form-check
  [unsavedFormCheckService]="unsavedFormCheckService"
></app-unsaved-form-check>
<div class="modal-header">
  <h4 class="modal-title">{{ title | translate }}</h4>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div
  [class.dropdown-opened]="tagFieldExpanded?.ngbDropdown?.isOpen()"
  class="modal-body new-message-dialog-modal-body"
>
  <ng-container
    *ngIf="isLoadingAttributeDescriptions$ | async; else sendMessageTemplate"
  >
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-container>
  <ng-template #sendMessageTemplate>
    <form [formGroup]="form">
      <div class="new-message">
        <p class="mb-0" *ngIf="description">{{ description | translate }}</p>

        <!-- Filter Area -->
        <ng-container *ngIf="messagePayload === null">
          <div class="row">
            <div class="new-message__recipient col-6">
              <div class="receipient-label">
                {{ 'UI.New-Message.RecipientType' | translate }}
              </div>
              <div class="receipient-dropdown">
                <app-boostrap-dropdown
                  class="truncate-selection"
                  [items]="items"
                  (selectItem)="handleGroupChanged($event)"
                  [container]="null"
                ></app-boostrap-dropdown>
              </div>
            </div>

            <div
              class="col-6 d-flex justify-content-start align-items-end pl-0"
            >
              <input
                class="form-control recipient-input"
                (input)="tagFieldExpanded.searchItems($event.target.value + '')"
                (focus)="tagFieldExpanded.ngbDropdown?.open()"
              />
            </div>
            <div class="col-12 mt-3">
              <app-tag-field-expanded
                #tagFieldExpanded
                (itemsChange)="
                  handleRecipientsChanged($event);
                  tagFieldExpanded.ngbDropdown?.close()
                "
                (newTagAdded)="handleRecipientAdded($event)"
                [(items)]="selectedRecipients"
                [placeholder]="('UI.Label.SearchName' | translate) + '....'"
                [searchData$]="searchData$"
                [disabledInput]="!shouldLoadSearchData"
                [hideTagInput]="true"
                [dropdownClass]="'recipient-dropdown'"
              ></app-tag-field-expanded>
            </div>
          </div>

          <div class="search__container new-message__recipient">
            <div class="receipient-label mt-0">Recipient</div>
            <div class="position-relative recipient-container">
              <app-tag-field
                [editable]="false"
                [items]="selectedRecipients"
              ></app-tag-field>
            </div>
          </div>

          <div
            *ngIf="errorMessage[formErrorKey]"
            class="alert alert-danger"
            translate
          >
            {{ errorMessage[formErrorKey] }}
          </div>
        </ng-container>
      </div>

      <div class="d-flex flex-row">
        <div
          class="sender-profile d-flex justify-content-center"
          *ngIf="showProfile"
        >
          <div class="message-to-profile">
            <img [src]="profile?.image" appDefaultAvatar appDynamicImgUrl />
          </div>
        </div>
        <div
          class="message-content-quill mb-3"
          [ngClass]="{ 'ml-0 mw-100': !showProfile }"
        >
          <app-form-field-custom
            #messageForm
            [formInput]="form"
            [type]="AttributeType.RTE"
            [attributeDescription]="contentAttributeDescription"
            [shouldValidateOnTouch]="true"
            [options]="{
              placeholder: placeholder | translate,
              allowEmojiOnRTE: true,
              allowImgOnRTE: allowImgOnRTE,
              allowCropImg: true,
              aspectRatio: 15 / 8
            }"
          >
          </app-form-field-custom>
        </div>
      </div>

      <div *ngIf="showIncludeSubOrgCheckbox">
        <div class="row">
          <div class="col-md-10 offset-md-2 col-xs-12 offset-xs-0">
            <div *ngIf="shouldShowAdminOnlyCheckbox" class="form-group mt-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="communityType"
                  id="defaultCheck2"
                  [value]="communityTypeEnum.AdminOnly"
                />
                <label
                  class="form-check-label checkbox-text-font-size"
                  for="defaultCheck2"
                  translate
                >
                  UI.NewMessageDialog.Option.AdminOnly
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row ml-3"
          *ngIf="form?.value?.communityType === communityTypeEnum.AdminOnly"
        >
          <div class="col-md-10 offset-md-2 col-xs-12 offset-xs-0">
            <div
              *ngIf="shouldShowAdminOnlyCheckbox && showIncludeSubOrgCheckbox"
              class="form-group mt-2"
            >
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="sendToAdminOfPartnerOrg"
                  id="defaultCheck2"
                />
                <label
                  class="form-check-label checkbox-text-font-size"
                  for="defaultCheck2"
                  translate
                >
                  UI.NewMessageDialog.Option.IncludeAdminPartnerOrg
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="showIncludeSubOrgCheckbox">
          <div class="col-md-10 offset-md-2 col-xs-12 offset-xs-0">
            <div class="form-group mt-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="communityType"
                  id="defaultCheck2"
                  [value]="communityTypeEnum.AdminAndMember"
                />
                <label
                  class="form-check-label checkbox-text-font-size"
                  for="defaultCheck2"
                  translate
                >
                  UI.NewMessageDialog.Option.AdminAndMember
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row ml-3"
          *ngIf="
            form?.value?.communityType === communityTypeEnum.AdminAndMember
          "
        >
          <div class="col-md-10 offset-md-2 col-xs-12 offset-xs-0">
            <div *ngIf="showIncludeSubOrgCheckbox" class="form-group mt-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="sendToAdminAndMemberOfPartnerOrg"
                  id="defaultCheck2"
                />
                <label
                  class="form-check-label checkbox-text-font-size"
                  for="defaultCheck2"
                  translate
                >
                  UI.NewMessageDialog.Option.IncludeAdminAndMemberPartnerOrg
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-mail-forwarding-checkbox
        *ngIf="showEmailCheckbox"
        [alignLeft]="hasContent"
        formControlName="isSendMail"
      ></app-mail-forwarding-checkbox>
    </form>
    <ng-content></ng-content>
  </ng-template>
</div>
<div class="modal-footer message-command">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="close()"
    translate
  >
    Cancel
  </button>
  <button
    appRequireLogin
    [disabled]="
      form.invalid || isSubmitting || messageForm?.imageInputDisplay?.uploading
    "
    type="button"
    ngbAutofocus
    class="btn btn-primary"
    (click)="send()"
  >
    <ng-container *ngIf="isSubmitting">
      <i class="fas fa-spinner fa-spin btn-icon-right"></i>
    </ng-container>
    <span translate>Send</span>
  </button>
</div>
