import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
// app
import { newNavs } from '@app/configs';
import { AuthenService } from '@core/authen/authen.service';
import { SessionService } from '@core/session.service';
import { NavInterface } from '@shared/interfaces/nav.interface';
import { GlobalFilterComponent } from '@src/app/components/master-layout/navs/components/global-filter/global-filter.component';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { BaseComponentWithServiceComponent } from '../../base/base-list/base-component-with-service/base-component-with-service.component';

@Component({
  selector: 'app-navs',
  templateUrl: './navs.component.html',
})
export class NavsComponent
  extends BaseComponentWithServiceComponent
  implements OnInit
{
  // tslint:disable-next-line:variable-name
  @Input() global_org_id: string; // Central config

  navs: NavInterface[] = newNavs;
  isNavsActivated = false;
  isSearchActivated = false;
  globalFilterRegisterEvent: any;
  isVisibleFilterButton = false;
  orgUrl = environment.jipUrl.organizations;

  profile: UserInterface;
  isShowSearchBar = false;
  searchText = '';

  homeNav = { route: '/', name: 'Home', jipIcon: 'home' };

  @ViewChild('globalFilter') globalFilter: GlobalFilterComponent;
  navDropdownItems: NavInterface[];

  get selectedOrg() {
    return this.globalFilter?.selectedOrg;
  }

  get tenantLogo(): string {
    return this.sessionService.getTenantLogo();
  }

  constructor(
    public sessionService: SessionService,
    public authenService: AuthenService,
    private breakpointObserver: BreakpointObserver
  ) {
    super(sessionService);

    this.breakpointObserver
      .observe([
        '(max-width: 1920px)',
        '(max-width: 1024px)',
        '(max-width: 768px)',
        '(max-width: 576px)',
      ])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints['(max-width: 576px)']) {
          this.navs = [...newNavs];
          this.navDropdownItems = [];
        } else if (state.breakpoints['(max-width: 768px)']) {
          this.navs = [];
          this.navDropdownItems = [...newNavs];
        } else if (state.breakpoints['(max-width: 1024px)']) {
          this.navs = newNavs.filter(
            (nav) => nav.route === '/news' || nav.route === '/challenges'
          );
          this.navDropdownItems = newNavs.filter(
            (nav) => nav.route !== '/news' && nav.route !== '/challenges'
          );
        } else if (state.breakpoints['(max-width: 1920px)']) {
          this.navs = [...newNavs];
          this.navDropdownItems = [];
        } else {
          this.navs = [...newNavs];
          this.navDropdownItems = [];
        }
      });
  }

  ngOnInit(): void {
    this.sessionService.isHeaderVisible = true;

    const globalSearchParam = StringUtils.getParamFromUrl(
      UrlParam.GlobalSearchTab
    );
    if (globalSearchParam) {
      this.isShowSearchBar = true;
    }
  }

  loadProfile(): void {
    this.authenService.getProfile().subscribe((profile) => {
      this.profile = profile;
    });
  }

  toggleNavs(): void {
    this.isNavsActivated = !this.isNavsActivated;
  }

  toggleSearch(): void {
    this.isSearchActivated = !this.isSearchActivated;
  }

  toggleSearchBar(): void {
    this.isShowSearchBar = !this.isShowSearchBar;
  }

  navigateToActiveInnovationSpaceDetail(id = null) {
    if (id) {
      FormUtils.navigateToWithLanguagePath(
        this.sessionService,
        this.orgUrl + '/' + id
      );
    } else {
      FormUtils.navigateToWithLanguagePath(this.sessionService, '/');
    }
  }

  onSearch() {
    this.globalFilter.search();
  }

  onClearText() {
    this.globalFilter?.resetSearch();
  }
}
