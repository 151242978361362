import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { JoinInRequestInterface } from '@src/app/components/dialogs/join-in-dialog/join-in-dialog.component';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { JoinState } from '@src/app/shared/interfaces/join.interface';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventHttpService extends BaseHttpService<EventInterface> {
  getApiRootPath(): string {
    return 'events';
  }

  join(id: number, dto: JoinInRequestInterface): Observable<any> {
    if (id) {
      let url = `${this.apiUrl}/${id}/participants/join`;
      if (dto && dto.targetTimezone !== null && !isNaN(dto.targetTimezone)) {
        url += `?targetTimezone=${dto.targetTimezone}`;
      }
      return this.http.post(url, dto);
    } else {
      return of(undefined);
    }
  }

  getJoinStates(ids: string[]): Observable<JoinState[]> {
    const url = `${this.apiUrl}/participants/check-join`;
    if (ids?.length > 0) {
      return this.http.get<JoinState[]>(url, { params: { ids } });
    }
    return of([]);
  }

  addToCalendar(id: number, params): Observable<HttpResponse<Blob>> {
    if (id) {
      const url = `${this.apiUrl}/${id}/calendar-file`;
      return this.http.get(url, {
        responseType: 'blob',
        observe: 'response',
        params
      });
    }
    else {
      return of(null);
    }
  }

  removeMyself(myId: number): Observable<boolean> {
    const url = `${this.apiUrl}/${myId}/participants/remove-myself`;
    return this.http.post<boolean>(url, {});
  }

  removeMyselfAsPartnerOrg(id, params) {
    const url = `${this.apiUrl}/${id}/partner/remove-myself`;
    return this.http.post(url, params);
  }
}
