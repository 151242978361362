import { Component, Input } from '@angular/core';
import { NavItemComponent } from '@src/app/components/master-layout/navs/components/nav-item/nav-item.component';
import { NavInterface } from '@src/app/shared/interfaces/nav.interface';

@Component({
  selector: 'app-nav-item-dropdown',
  templateUrl: './nav-item-dropdown.component.html',
})
export class NavItemDropdownComponent extends NavItemComponent {

}
