import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { StorageEnum } from '@shared/enums/storage.enum';
import { EntityGlobalFilterCriteria } from '@shared/interfaces/filters/global-filter.interface';
import { OrganizationFilter } from '@shared/interfaces/organization.interface';
import { UserConfigManagementService } from '@shared/services/user-config-management.service';
import { NewsMessagesFilterType as FilterType } from '@src/app/shared/enums/filter.enum';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-news-messages-filter',
  templateUrl: './news-messages-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsMessagesFilterComponent implements OnInit, OnChanges {
  @Input() globalFilterCriteria: EntityGlobalFilterCriteria;

  @Input() showFollowingOrLikeFilter = false;

  @Output() filterChanged = new EventEmitter<FilterType>();

  @Output() subFilterChanged = new EventEmitter<FilterType>();

  @Input() mainFilter

  @Input() newsAndMessagesTab: string;

  subFilter = FilterType.All;

  selectedOrg$ = new BehaviorSubject<OrganizationFilter>(null);

  currentOrgsFilter: OrganizationFilter[] = [];

  readonly subFilters = [
    {
      value: FilterType.All,
      label: 'UI.Common.AllResults',
      hidden: () => false,
    },

    {
      value: FilterType.FollowingOrLike,
      label: 'UI.New-Message.IFollowingOrLike',
      hidden: () => !this.showFollowingOrLikeFilter,
    },

    {
      value: FilterType.OrganizationIds,
      label: 'UI.New-Message.ShowOnlyInnovationSpace',
      hidden: () => !this.globalFilterCriteria?.organizationIds?.length,
    },
  ];

  readonly mainFilters: Record<string, string>[] = [
    {
      key: FilterType.News,
      label: 'News',
    },
    {
      key: FilterType.Message,
      label: 'Messages',
    },
  ];

  readonly FilterType = FilterType;

  constructor(private readonly userConfig: UserConfigManagementService) {}

  async ngOnInit(): Promise<void> {
    await this.getOrgsFilter();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (
      changes.globalFilterCriteria?.currentValue ||
      changes.showFollowingOrLikeFilter
    ) {
      if (!this.currentOrgsFilter?.length) {
        await this.getOrgsFilter();
      }

      const newOrgFilter = this.currentOrgsFilter?.find((item) => {
        return item.id === this.globalFilterCriteria?.organizationIds[0];
      });

      if (
        this.subFilter === this.FilterType.OrganizationIds &&
        this.selectedOrg$.value?.id !== newOrgFilter?.id
      ) {
        this.subFilterChanged.emit(this.subFilter);
      }

      this.selectedOrg$.next(newOrgFilter);
    }
  }

  onFilterChange(filter: FilterType): void {
    this.filterChanged.emit(filter);
  }

  onSubFilterChange(filter: FilterType): void {
    this.subFilterChanged.emit(filter);
  }

  private async getOrgsFilter(): Promise<void> {
    this.currentOrgsFilter = await this.userConfig.get(
      StorageEnum.orgFilterToken
    );
  }
}
