export enum EventSectionIndex {
  Title,
  General,
  Privacy,
  Organizer,
  PartnerOrganizations,
  Agenda,
  Description,
  Stakeholders,
  Location,
  State
}

export enum EventState {
  Created = 'Created',
  Approved = 'Approved',
  Published = 'Published',
  FullyBooked = 'FullyBooked',
  Cancelled = 'Cancelled',
  Passed = 'Passed'
}

export enum EventStamp {
  Ongoing,
  Passed,
  SaveTheDate,
  FullyBooked,
  Cancelled
}
