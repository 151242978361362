import { Component, Input, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserInterface } from '@shared/interfaces/user.interface';
import { SendMessageDialogComponent } from '@src/app/components/dialogs/send-message-dialog/send-message-dialog.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { Observable } from 'rxjs';
import { untilDestroyed } from '../../functions/until-destroyed';

@Component({
  selector: 'app-send-message-btn',
  templateUrl: './send-message-btn.component.html'
})
export class SendMessageBtnComponent implements OnDestroy {
  @Input() receiver: UserInterface;

  profile$: Observable<UserInterface>;
  isLogin = false;

  constructor(
    private authenSerivce: AuthenService,
    private modalService: NgbModal
  ) {
    this.profile$ = this.authenSerivce.getProfile();
    this.authenSerivce.isLogin()
      .pipe(untilDestroyed(this))
      .subscribe((isLogin: boolean) => {
          this.isLogin = isLogin;
        });
  }

  openSendMessage(): void {
    if (this.isLogin) {
      const receiverFullName = `${this.receiver.firstName || ''} ${this.receiver.lastName || ''}`;
      const modalRef = this.modalService.open(SendMessageDialogComponent, {
        centered: true,
        backdrop: 'static',
        size: 'lg',
        scrollable: false,
        windowClass: 'message-modal-custom',
      });

      modalRef.componentInstance.toPersonId = this.receiver.id;
      modalRef.componentInstance.receiverName = receiverFullName;
    }
  }

  ngOnDestroy(): void { /* */ }
}
