export enum InternalIcon {
  Following = 'icon-following',
  BringForward = 'icon-bring-forward',
  GlobeCursor = 'icon-globe-cursor',
  ExpandTable = 'icon-expand-table',
  InnovationSpaceWhite = 'icon-innovation-space-white',
  InnovationSpace = 'icon-innovation-space',
  Rocket = 'rocket',
  SIGlobeCursor = 'switzerland-innovation-globe-cursor',
  UserFollow = 'user-follow',
  ThumbsUp = 'thumbs-up',
  Share = 'share',
  CommentDots = 'comment-dots',
  Comments = 'comments',
  SuitCase = 'suitcase',
  UserClock = 'user-clock',
  Calendar = 'calendar',
  Community = 'community',
  Home = 'home',
  Lightbulb = 'lightbulb',
  News = 'news',
  Organization = 'organization',
  Unicorn = 'unicorn',
  JipLogo = 'jip-logo',
}

export enum IconType {
  SVG = 'svg',
  PNG = 'png',
  GIF = 'gif',
}

export enum InteractionIcon {}
