<div class="jip-login" [class.login-wide-screen]="true">
  <div
    *ngIf="logo_display_option === 'jip'"
    class="jip-login__logo text-center mb-3"
  >
    <app-jip-logo
      class="jip-login__logo--others"
      name="jip-logo"
      width="380px"
      height="106px"
    ></app-jip-logo>
    <app-jip-logo
      class="jip-login__logo--mobile"
      name="jip-logo"
      width="380px"
      height="106px"
    ></app-jip-logo>
  </div>

  <div
    *ngIf="logo_display_option === 'innovation_space'"
    class="jip-login__org-logo"
  >
    <app-organization-logo
      [className]="'filter__org-logo text-center'"
      [organizationLogo]="
        (centralConfig?.read(global_org_id) | async)?.logo.url
      "
    ></app-organization-logo>
  </div>

  <div class="jip-login__header d-flex justify-content-center mb-3">
    <span class="jip-login__header-title" translate
      >UI.EventRegistration.RegisterToEvent</span
    >
  </div>

  <div class="card p-3">
    <app-event-card *ngIf="entity" [data]="entity"></app-event-card>

    <form class="mt-4" [formGroup]="eventForm">
      <div class="form-group">
        <div class="row">
          <div
            *ngFor="let item of formFields"
            class="mb-3"
            [ngClass]="[
              'col-' + item.columnSize[2],
              'col-md-' + item.columnSize[1],
              'col-lg-' + item.columnSize[0]
            ]"
          >
            <label *ngIf="item.label">
              {{ item.label | translate }}
            </label>

            <ng-container [ngSwitch]="item.key">
              <ng-container *ngSwitchCase="FormKey.TermAgreement">
                <div class="form-group d-flex justify-content-center">
                  <app-term-agreement-checkbox
                    [formControlName]="item.key"
                  ></app-term-agreement-checkbox>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="FormKey.Recaptcha">
                <div class="form-group confirm-account-recaptcha">
                  <app-google-recapcha
                    [formInput]="eventForm"
                  ></app-google-recapcha>
                </div>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <input
                  class="form-control"
                  [formControlName]="item.key"
                  autocomplete="off"
                />
                <div
                  *ngIf="
                    eventForm.get(item.key)?.invalid &&
                    (eventForm.get(item.key)?.dirty ||
                      eventForm.get(item.key)?.touched)
                  "
                  class="alert alert-danger"
                >
                  <div
                    *ngIf="
                      item.requiredLabel &&
                      eventForm.get(item.key)?.hasError('required')
                    "
                  >
                    {{ item.requiredLabel | translate }}
                  </div>

                  <div
                    *ngIf="
                      eventForm.get(item.key)?.hasError('notEmptyOrWhitespace')
                    "
                  >
                    {{ 'UI.Common.FirstName' | translate }}
                    {{ 'cannot be empty or whitespace' | translate }}.
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="form-group text-center">
          <button
            type="submit"
            class="btn btn-primary"
            appDebounceClick
            (debounceClick)="registerEvent()"
            [disabled]="eventForm.invalid || isSubmitting"
          >
            <ng-container *ngIf="isSubmitting">
              <i class="fas fa-spinner fa-spin"></i>
            </ng-container>
            <span translate>Register</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
