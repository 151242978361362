import {
  BehaviorSubject,
  combineLatest,
  Observable,
  OperatorFunction,
  PartialObserver,
  Subscribable,
  Unsubscribable,
} from 'rxjs';
import { map, scan, switchMap, tap } from 'rxjs/operators';

export class InfiniteScrollDataAdapter implements Subscribable<any> {
  // Loading state as observable
  loading$ = new BehaviorSubject(false);

  // if you can load more element....
  hasMore$ = new BehaviorSubject(true);

  private _searchKeyword: string = '';

  private _dataSource$: Observable<any>;

  // Current offset as observable
  private _pageIndex$ = new BehaviorSubject(0);

  // Current limit as observable
  private _pageSize$: BehaviorSubject<number>;

  constructor(
    private _dataSource: (
      searchKeyword: string,
      pageIndex: number,
      pageSize: number
    ) => Observable<any>,
    _limit: number
  ) {
    // Set The limit into an observable ...
    this._pageSize$ = new BehaviorSubject(_limit || 10);

    // Load Data source....
    this._dataSource$ = combineLatest([this._pageIndex$, this._pageSize$]).pipe(
      // Loading On
      tap(() => this.loading$.next(true)),

      // Load Data from data source
      switchMap(([pageIndex, pageSize]) =>
        this._dataSource(this._searchKeyword, pageIndex, pageSize).pipe(
          map((data) => ({
            data,
            pageSize,
            pageIndex,
          }))
        )
      ),
      // Set hasMore$
      tap(({ pageIndex, pageSize, data }) =>
        this.hasMore$.next(data.length >= pageSize)
      ),
      // Accumulate data into 1 array
      scan((acc, data) => {
        // if Offset is 0 THEN
        return this._pageIndex$.getValue() === 0
          ? // Reset the data counter
            data.data
          : // Put the data at the current list
            [...acc, ...data.data];
      }, []),

      // Turn off loading
      tap(() => this.loading$.next(false))
    );
  }

  subscribe(observer?: PartialObserver<any>): Unsubscribable;

  subscribe(
    next: null | undefined,
    error: null | undefined,
    complete: () => void
  ): Unsubscribable;

  subscribe(
    next: null | undefined,
    error: (error: any) => void,
    complete?: () => void
  ): Unsubscribable;

  subscribe(
    next: (value: any) => void,
    error: null | undefined,
    complete: () => void
  ): Unsubscribable;

  subscribe(
    next?: (value: any) => void,
    error?: (error: any) => void,
    complete?: () => void
  ): Unsubscribable;

  subscribe(
    observer?: PartialObserver<any> | null | undefined | ((value: any) => void),
    error?: null | undefined | ((error: any) => void),
    complete?: () => void
  ): Unsubscribable {
    // @ts-ignore
    return this._dataSource$.subscribe(...arguments);
  }

  pipe(...operators: OperatorFunction<any, any>[]): Observable<any> {
    // @ts-ignore
    return this._dataSource$.pipe(...operators);
  }

  /**
   * This method will allow you to reload the list
   */
  reload(searchKeyword?: string): void {
    if (arguments.length) {
      this._searchKeyword = searchKeyword;
    }
    this._pageIndex$.next(0);
  }

  /**
   * This method will allow you to load more in the list
   */
  loadMore(): void {
    if (this.loading$.getValue()) {
      return;
    }
    this._pageIndex$.next(this._pageIndex$.getValue() + 1);
  }
}
