import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DirectMessage } from '@shared/interfaces/direct-message.interface';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { FormComponentInterface } from '@src/app/core/form/form-error.service';
import { UnsavedFormCheckService } from '@src/app/core/form/unsaved-form-check.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { VentureHttpService } from '@src/app/core/http/venture.http.service';
import { ToastService } from '@src/app/core/toast.service';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { ApiResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { finalize } from 'rxjs/operators';
import { SendMessageBoxBaseComponent } from '../send-message-box-base/send-message-box-base.component';

@Component({
  selector: 'app-get-in-touch-dialog',
  templateUrl: './get-in-touch-dialog.component.html',
  providers: [{ provide: VentureHttpService, useClass: VentureHttpService }],
})
export class GetInTouchDialogComponent
  extends SendMessageBoxBaseComponent
  implements OnInit, FormComponentInterface
{
  @Input() venture: VentureInterface;

  //#region SERVICES
  private toastService: ToastService;
  //#endregion End Services

  constructor(
    public activeModal: NgbActiveModal,
    public unsavedFormCheckService: UnsavedFormCheckService,
    protected authenService: AuthenService,
    protected sendMessageHttpService: SendMessageHttpService,
    protected injector: Injector,
    private ventureService: VentureHttpService
  ) {
    super(
      activeModal,
      unsavedFormCheckService,
      authenService,
      sendMessageHttpService,
      injector
    );
    this.toastService = this.injector.get<ToastService>(ToastService);
  }

  send(): void {
    this.isSubmitting = true;
    const payload: DirectMessage = {
      comment: this.content?.value,
      isSendMail: !!this.isSendMail?.value,
    };

    this.ventureService
      .sendGetInTouchMessage(this.venture.id, payload)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isSubmitting = false;
        })
      )
      .subscribe({
        next: (data) => {
          const error = data as ApiResponse;
          if (error && error.status === 400) {
            console.log('error when sent message: ', error);
          }
          if (data) {
            this.toastService.showSuccess('UI.Toast.MessageSuccessfullySent');
            this.activeModal.close(true);
          }
        },
        error: (error) => {
          console.log('error when sent message: ', error);
        },
      });
  }
}
