<div class="search__box" (clickOutside)="onClickOutSide()">
  <div class="search__container d-flex flex-column">
    <div class="w-100 position-relative">
      <span class="search__icon-wrapper">
        <i
          class="search__icon fas"
          [ngClass]="isSearching ? 'fa-spinner fa-spin' : 'fas fa-search'"
        ></i>
      </span>

      <input
        #searchInput
        appRequireSession
        id="search"
        type="text"
        class="search__input form-control form-controls-sm"
        [(ngModel)]="searchText"
        (ngModelChange)="onChangeSearchText()"
        [placeholder]="searchPlaceholder || 'UI.Label.SearchName' | translate"
        (focus)="onFocusSearchInput()"
        (blur)="onBlurSearchInput()"
        (click)="onClickSearchInput()"
        autocomplete="off"
      />

      <virtual-scroller
        #scroll
        *ngIf="isDropdownOpen && virtualScrollItems.length > 0"
        class="align-self-start dropdown-menu show"
        [ngClass]="resultContainer"
        [bufferAmount]="3"
        [enableUnequalChildrenSizes]="true"
        [items]="virtualScrollItems"
        (vsEnd)="loadMore($event)"
        [style.height]="getDynamicHeight()"
      >
        <ng-container *ngFor="let result of scroll.viewPortItems">
          <button class="dropdown-item" (click)="onSelectOrg(result)">
            <div
              *ngIf="result.tagFieldType === orgsSearchTagFieldTypeEnum.FillOrg"
              class="search-result"
            >
              <div class="search-result__fill-button">
                <div class="text-fill-org">
                  <div>{{ 'Fill-Org' | translate }}</div>
                  <div class="fill-org-hint">
                    {{ 'Fill-Org-Hint' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="result.tagFieldType === orgsSearchTagFieldTypeEnum.Header"
              class="search-result"
            >
              <span>{{ 'Suggestions' | translate }}</span>
            </div>
            <div *ngIf="!result.tagFieldType" class="search-result">
              <div
                class="search-result__logo"
                [ngStyle]="{
                  'background-image':
                    'url(' + (result.logo?.url || result.logo) + ')'
                }"
              ></div>

              <div class="search-result__name truncate-line-1">
                <ngb-highlight
                  class="truncate-line-1"
                  [result]="result.name || result.orgName"
                  [term]="searchText"
                ></ngb-highlight>
              </div>
              <div *ngIf="result.isMyOrganisation" class="search-result__badge">
                <span translate>My Organization</span>
              </div>
            </div>
          </button>
        </ng-container>
        <div *ngIf="isSearching" class="w-100 text-center">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </virtual-scroller>

      <span
        class="search__icon-wrapper search__icon-wrapper--last pointer-cursor"
      >
        <i
          *ngIf="this.searchText"
          appDebounceClick
          (debounceClick)="resetSearchText()"
          class="reset-search far fa-times"
          focusable="false"
        >
        </i>
      </span>
    </div>
  </div>
</div>
