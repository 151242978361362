<ul
  *ngIf="data"
  class="list-inline people__actions interaction-bar justify-content-between px-2"
>
  <li class="list-inline-item people__actions-item">
    <ng-container *ngIf="isLoadingFollowingsCount; else followersBtn">
      <div class="text-center">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </ng-container>
    <ng-template #followersBtn>
      <a
        appDebounceClick
        (debounceClick)="showEntityData(data); showPeopleModal = true"
        class="d-flex"
      >
        <app-internal-icon
          [className]="'filter-gray'"
          [name]="'user-follow'"
        ></app-internal-icon>
        <span class="people__actions-label font-weight-500 text-gray-600">
          <span class="text-hide-small">{{ 'Followers' | translate }}</span>
          <span [innerHTML]="data.followerCount | parentheses"></span>
        </span>
      </a>
    </ng-template>
  </li>

  <app-send-message-btn [receiver]="data"> </app-send-message-btn>

  <li *ngIf="isSamePerson && showDeactivateProfileBtn">
    <button
      class="btn btn-primary d-flex align-items-center w-100 ml-1 justify-content-between font-size-sm"
      (click)="openDeactivateProfileDialog(data)"
    >
      <i class="fas fa-user-times"></i>
      <span class="ml-2">Deactivate my profile</span>
    </button>
  </li>

  <li
    *ngIf="!isSamePerson"
    class="list-inline-item people__actions-item"
    role="button"
  >
    <a appDebounceClick appRequireLogin (debounceClick)="openSharePerson(data)">
      <app-internal-icon
        [height]="20"
        [name]="'share'"
        [className]="'filter-gray'"
      ></app-internal-icon>
      <span
        class="people__actions-label font-weight-500 text-hide-small text-gray-600"
      >
        {{ 'UI.Interaction.Share' | translate }}
      </span>
    </a>
  </li>
</ul>

<app-people-modal
  *ngIf="showPeopleModal"
  [entityId]="selectedEntityId"
  [entityTitle]="data?.firstName + ' ' + data?.lastName"
  modalTitle="Followers"
  [httpService]="httpService"
  (dismissModal)="showPeopleModal = false"
></app-people-modal>
