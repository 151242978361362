import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styles: [],
})
export class CheckBoxComponent {
  @Input() elementId: string;
  @Input() content: string;
  @Input() disabled = false;
  @Input() isChecked = false;
  @Input() tooltip: string;

  @Output() selectItem = new EventEmitter<boolean>();

  toggle(event: Event): void {
    // tslint:disable-next-line: no-string-literal
    const value = event.target['checked'];
    this.isChecked = value;
    this.selectItem.emit(value);
  }
}
