import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GlobalFilterStoredService } from '@core/services/global-filter-stored.service';
import { TemplateName } from '@shared/constants/visibility-config.const';
import { AttributeValuePipe } from '@shared/pipes/attribute-value.pipe';
import { getActiveSectionIds } from '@shared/utils/venture.utils';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import {
  VENTURE_CHARACTERISTICS,
  VENTURE_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES,
  VentureHttpService,
} from '@src/app/core/http/venture.http.service';
import { SessionService } from '@src/app/core/session.service';
import { transformToTagModaByKey } from '@src/app/shared/components/tag-field/tag-field.component';
import {
  AttributeType,
  PatternType,
} from '@src/app/shared/enums/attribute-type.enum';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import { DeactiveShowType } from '@src/app/shared/enums/deactive-show-type.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import {
  AttributeDescription,
  AttributeValue,
  CustomAtrributeValue,
} from '@src/app/shared/interfaces/attribute-description.interface';
import { MetadataRecord } from '@src/app/shared/interfaces/metadata-table.interface';
import {
  TimestampModel,
  TimestampResponse,
} from '@src/app/shared/interfaces/timestamp.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { VentureTimestampInterface } from '@src/app/shared/interfaces/venture.interface';
import { ArrayUtils } from '@src/app/shared/utils/array-utils';
import {
  CustomMetadataUtils,
  ORG_SPECIFIC_INFO_SUPPORT_ATTRIBUTE_TYPE,
} from '@src/app/shared/utils/custom-metadata-utils';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { EventBusService } from 'ngx-eventbus';
import { from } from 'rxjs';
import { finalize, skip, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-venture-timestamp',
  templateUrl: './venture-timestamp.component.html',
})
export class VentureTimestampComponent implements OnInit, OnDestroy {
  @Input() timestampId: string;
  @Input() entityId: number;

  @Input() uiTemplateConfig: TemplateName = TemplateName.Default;

  @Input() globalOrgId: string; // Central config

  form: UntypedFormGroup;
  entity: VentureTimestampInterface;
  entityName = EntityName.Venture;
  timestampInfo: TimestampModel;

  profile: UserInterface;
  isOwner = false;
  isTeam = false;
  isLoading = false;
  allType = AttributeType;
  DateFormat = DateFormat;

  customMetadata: MetadataRecord = {
    attributeDescriptions: [],
    attributeValues: [],
  };
  deactiveShowTypeEnum = DeactiveShowType;

  customUserRoles: CustomAtrributeValue[] = [];
  customAdminRoles: CustomAtrributeValue[] = [];

  shouldShowHiddenMarkBackground: boolean;

  VENTURE_CHARACTERISTICS = VENTURE_CHARACTERISTICS;
  VENTURE_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES =
    VENTURE_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES;

  uiTemplateName: TemplateName = TemplateName.Default;

  constructor(
    public baseHttpService: VentureHttpService,
    public sessionService: SessionService,
    private authenService: AuthenService,
    private customFormService: CustomFormService,
    private fb: UntypedFormBuilder,
    private attributeValuePipe: AttributeValuePipe,
    private eventBus: EventBusService,
    private filterStoredService: GlobalFilterStoredService
  ) {}

  ngOnInit(): void {
    if (this.timestampId) {
      this.isLoading = true;
      this.sessionService.apiReady$
        .pipe(untilDestroyed(this))
        .subscribe((apiReady) => {
          if (apiReady) {
            this.getTimestameById();
          }
        });
    } else {
      this.backToEntity();
    }

    this.listenFilterChanged();
  }

  backToEntity(): void {
    const url = environment.jipUrl.venture + '/' + this.entityId;
    FormUtils.navigateToWithLanguagePath(this.sessionService, url);
  }

  ngOnDestroy(): void {
    /**/
  }

  getFieldOptions(propertyName: string): AttributeDescription {
    return this.entity.attributeDescriptions.find(
      (x) => x.propertyName === propertyName
    );
  }

  shouldShowVentureDetails(): boolean {
    if (this.isOwner) {
      return true;
    }

    for (const key of VENTURE_CHARACTERISTICS) {
      if (
        this.getFieldOptions(key)?.isVisibleInSystem ||
        this.form?.getRawValue()[key]
      ) {
        return true;
      }
    }
  }

  listenFilterChanged(): void {
    if (this.uiTemplateConfig === TemplateName.Default) return;

    this.filterStoredService
      .getCurrentFilterToken()
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = true;
        this.getTimestameById();
      });
  }

  checkBestPracticeAttribute(
    customMetadata: MetadataRecord,
    uiTemplate: TemplateName
  ): boolean {
    return (
      uiTemplate === TemplateName.Reduced &&
      !!customMetadata?.attributeDescriptions?.find(
        (x) => x.propertyName === 'BestPractices'
      )
    );
  }

  async getOrgId(): Promise<number> {
    if (this.sessionService.isHeaderVisible) {
      const filterCriteria = await this.filterStoredService.getFilterCriteria();

      return +this.globalOrgId || filterCriteria.organizationIds?.[0];
    }

    return +this.globalOrgId;
  }

  getActiveIds(uiTemplate: TemplateName): string[] {
    return getActiveSectionIds(uiTemplate);
  }

  private handleCustomMetadata(
    attributeDescriptions: AttributeDescription[] = [],
    attributeValues: AttributeValue[] = []
  ): void {
    const filterPropertyName = 'AdminRole';
    this.customAdminRoles = [];
    this.customUserRoles = [];

    if (attributeDescriptions?.length > 0) {
      const adminRoleAttrDes = attributeDescriptions.find(
        (x) => x.propertyName === filterPropertyName
      );
      if (adminRoleAttrDes) {
        this.customAdminRoles = adminRoleAttrDes.choice.selections;

        const adminRoleAttrVal = attributeValues.find(
          (x) => x.propertyName === filterPropertyName
        );
        if (this.entity?.initiator) {
          this.entity.initiator.role =
            adminRoleAttrVal?.describedValue?.selection ??
            adminRoleAttrDes.defaultValue;
        }
        // Remove Admin Role from Dedicated Section
        attributeDescriptions = attributeDescriptions.filter(
          (x) => x.propertyName !== filterPropertyName
        );
        attributeValues = attributeValues.filter(
          (x) => x.propertyName !== filterPropertyName
        );
      }
    }

    this.customMetadata = CustomMetadataUtils.filterMetadataRecord(
      attributeDescriptions,
      attributeValues,
      [PatternType.Dedicated],
      ORG_SPECIFIC_INFO_SUPPORT_ATTRIBUTE_TYPE
    );

    if (this.entity?.id) {
      this.baseHttpService
        .getStakeHolderCustomAttribute(this.entity?.id)
        .subscribe((attrDesList: AttributeDescription[]) => {
          const roleAttrDes = attrDesList?.find(
            (x) => x.propertyName === 'Role'
          );
          this.customUserRoles = roleAttrDes
            ? roleAttrDes.choice?.selections
            : [];
        });
    }
  }

  private getTimestameById(): void {
    const getTimestampById = (organizationId) => {
      const params = organizationId
        ? new HttpParams().set('organizationId', organizationId)
        : null;

      return this.baseHttpService.getTimestampById(this.timestampId, params);
    };

    from(this.getOrgId())
      .pipe(
        switchMap(getTimestampById),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((res: TimestampResponse<VentureTimestampInterface>) => {
        if (res) {
          const entity = res.result;
          this.timestampInfo = res.timestampInfo;
          this.entity = entity;

          this.handleCustomMetadata(
            entity.attributeDescriptions,
            entity.attributeValues
          );
          this.createFormControl(entity.attributeDescriptions);
          setTimeout(() => {
            this.bindFormValue(entity);
          });

          this.authenService.profile$.pipe(filter(Boolean)).subscribe((profile: UserInterface) => {
            this.isTeam = ArrayUtils.hasItem(
              entity?.stakeholders,
              profile,
              'id'
            );
            this.isOwner = entity.initiator?.id === profile?.id || this.isTeam;
            this.shouldShowHiddenMarkBackground =
              this.isOwner && this.entity?.isNDARequired;
            this.profile = profile;
          });

          this.uiTemplateName = this.getDisplayUiTemplate();
        }
      });
  }

  private getDisplayUiTemplate(): TemplateName {
    const itemUiTemplate = this.attributeValuePipe.transform(
      this.entity.attributeValues,
      'UiTemplate'
    )?.describedValue?.value;

    return this.uiTemplateConfig !== TemplateName.Default
      ? itemUiTemplate
      : TemplateName.Default;
  }

  private createFormControl(lstAttrDes: AttributeDescription[]): void {
    const controls = this.customFormService.createControl(lstAttrDes, [], true);
    const formGroup = {
      ...controls,
    };
    this.form = this.fb.group(formGroup);
  }

  private bindFormValue(entity: VentureTimestampInterface): void {
    for (const attr of entity.attributeDescriptions) {
      const key = attr.propertyName;
      this.updateFieldValue(key);
    }
  }

  private updateFieldValue(key): void {
    this.form.patchValue({
      [key]: this.getFormFieldValueFromEntity(key),
    });
  }

  private getFormFieldValueFromEntity(name: string): any {
    if (name === 'Demands') {
      return transformToTagModaByKey(
        this.entity.demands,
        true,
        'content',
        'content'
      );
    }
    if (name === 'LinkCompanyHomePage') {
      return [
        {
          url: this.entity?.linkCompanyHomePage?.url,
          faIconClass: 'fa-home',
        },
      ];
    }

    const entityValue = this.entity[StringUtils.toLowerCaseFirstLetter(name)];
    if (entityValue !== undefined) {
      return entityValue;
    }
    // Handle for custom metadata record
    if (this.customMetadata.attributeDescriptions?.length > 0) {
      const lstCustomMetadataPropertyName =
        this.customMetadata.attributeDescriptions.map((x) => x.propertyName);
      if (lstCustomMetadataPropertyName.includes(name)) {
        return CustomMetadataUtils.getAttributeValueByPropertyName(
          this.customMetadata.attributeDescriptions,
          this.customMetadata.attributeValues,
          name
        );
      }
    }
  }
}
