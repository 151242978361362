import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsMessagesHttpService } from '@src/app/core/http/news-messages-http.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { ToastService } from '@src/app/core/toast.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { ChatMessage } from '@src/app/shared/interfaces/chat-message.interface';
import { News } from '@src/app/shared/interfaces/news.interface';
@Component({
  selector: 'app-delete-post-dialog',
  templateUrl: './delete-post-dialog.component.html',
  styles: [],
})
export class DeletePostDialogComponent {
  @ViewChild('modalRef') modalRef: ElementRef;
  @Input() title = 'Confirmation';
  @Input() message: string;
  @Input() confirmLabel = 'Ok';
  @Input() isLeaveSite: boolean;
  @Input() post: News | ChatMessage;
  @Input() entityName: EntityName.News | EntityName.Message;

  isSubmitting = false;

  private getDeleteEntityAPI = {
    [EntityName.News]: (id: number) =>
      this.newsMessageHttpService.deletePost(id),
    [EntityName.Message]: (id: number) =>
      this.sendMessageHttpService.deleteThreadMessage(id),
  };

  constructor(
    public readonly activeModal: NgbActiveModal,
    private newsMessageHttpService: NewsMessagesHttpService,
    private sendMessageHttpService: SendMessageHttpService,
    private toastService: ToastService
  ) {}

  confirm(): void {
    this.isSubmitting = true;

    const deleteMethod = this.getDeleteEntityAPI[this.entityName];

    deleteMethod(this.post.id)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.toastService.showSuccess('UI.Toast.DeletedSuccessfully');
          this.isSubmitting = false;
          this.activeModal.close(true);
        },
        () => {
          this.isSubmitting = false;
          this.toastService.showError('UI.Toast.DeletedFailed');
        }
      );
  }

  cancel(): void {
    this.activeModal.close(false);
  }

  ngOnDestroy(): void {
    /** */
  }
}
