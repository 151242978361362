import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from './../components/confirmation/confirmation.component';
import { NotificationComponent } from './../components/notification/notification.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private modalService: NgbModal) {}

  show(
    title = '',
    message = '',
    btnText = '',
    isHtmlMessage = false
  ): NgbModalRef {
    const modalRef = this.modalService.open(NotificationComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.isHtmlMessage = isHtmlMessage;
    if (btnText) {
      modalRef.componentInstance.btnText = btnText;
    }
    return modalRef;
  }

  confirm(
    title = '',
    message = '',
    isLeaveSite = false,
    confirmLabel = 'OK'
  ): NgbModalRef {
    const modalRef = this.modalService.open(ConfirmationComponent, {
      centered: true,
      backdrop: 'static',
      windowClass: 'confirm-modal',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.confirmLabel = confirmLabel;
    modalRef.componentInstance.isLeaveSite = isLeaveSite;
    return modalRef;
  }
}
