<ng-container *ngIf="!isLoadingEntity && !isGoingToOtherPage && entity">
  <div class="tenant-configuration">
    <ngb-accordion [activeIds]="['tenant-attributes']" [destroyOnHide]="false">
      <ngb-panel
        cardClass="venture__body venture__accordion ignore-overflow"
        id="tenant-attributes"
      >
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            class="btn d-flex align-items-center justify-content-between w-100 text-left"
            ngbPanelToggle
            type="button"
          >
            <div translate class="pull-left float-left">
              <span
                class="venture__company-header mr-5 d-block d-lg-inline-block"
                >{{ 'UI.TenantConfiguration.Attributes' | translate }}</span
              >
            </div>
            <i
              class="fas float-right pull-right venture__company-header"
              [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
            ></i>
          </button>
        </ng-template>

        <ng-template ngbPanelContent>
          <div
            class="venture__body widget__card--background m-0"
            *ngIf="form && formSections.length > 0"
          >
            <app-form-section
              #sectionTenantAttributes
              [index]="TenantConfigurationSectionIndex.TenantAttributes"
              [canSave]="
                isValidSection(TenantConfigurationSectionIndex.TenantAttributes)
              "
              [showEditButton]="!sectionTenantAttributes.editting"
              [saveSectionCallback]="saveCustomAttributeSection.bind(this)"
              (cancelSection)="cancelSection($event)"
              (editIndexChange)="markEditingSectionIndex($event)"
            >
              <ng-container
                *ngFor="
                  let propertyName of formSections[
                    TenantConfigurationSectionIndex.TenantAttributes
                  ].fieldsName;
                  index as fieldIndex
                "
              >
                <div class="tenant-form-field" [class.mt-0]="fieldIndex === 0">
                  <ng-container
                    [ngSwitch]="getAttributeTypeByPropertyName(propertyName)"
                  >
                    <ng-container *ngSwitchCase="AttributeType.Image">
                      <div
                        class="form-group position-relative mt-2 d-flex flex-column"
                      >
                        <div class="venture__label widget__label">
                          <label [for]="propertyName">
                            {{ getFieldOptions(propertyName)?.displayName }}
                          </label>
                        </div>

                        <ng-container
                          *ngIf="
                            sectionTenantAttributes.editting;
                            else imgViewRef
                          "
                        >
                          <app-file-upload
                            [multiLocale]="
                              getFieldOptions(propertyName)?.systemType ===
                              SystemType.MultilingualImage
                            "
                            [disabled]="false"
                            (srcUrlChange)="
                              getFormControlByPropertyName(
                                propertyName
                              )?.setValue($event)
                            "
                            [inputId]="propertyName"
                            [allowCropImg]="
                              propertyName | call : isDefaultBanner
                            "
                            [aspectRatio]="20 / 7"
                          >
                            <div
                              *ngIf="
                                !getFormControlByPropertyName(propertyName)
                                  ?.value?.url
                              "
                              class="tenant-logo card upload-file__container justify-content-center align-items-center"
                              [ngClass]="{
                                'alert alert-danger':
                                  getFormControlByPropertyName(propertyName)
                                    ?.invalid,
                                'tenant-default-logo':
                                  propertyName | call : isDefaultLogo,
                                'tenant-default-banner':
                                  propertyName | call : isDefaultBanner
                              }"
                            >
                              <i
                                class="fas fa-camera fa-lg"
                                [ngClass]="{
                                  'upload-file__icon':
                                    getFormControlByPropertyName(propertyName)
                                      ?.valid
                                }"
                              ></i>
                            </div>
                            <div
                              *ngIf="
                                getFormControlByPropertyName(propertyName)
                                  ?.value?.url
                              "
                              class="tenant-logo card upload-file__container border-0"
                              [ngClass]="{
                                'tenant-default-logo':
                                  propertyName | call : isDefaultLogo,
                                'tenant-default-banner':
                                  propertyName | call : isDefaultBanner
                              }"
                            >
                              <img
                                [src]="
                                  getFormControlByPropertyName(propertyName)
                                    ?.value?.url
                                "
                                appDefaultPlaceholder
                                appDynamicImgUrl
                                [default]="placeholderImg"
                              />
                            </div>
                          </app-file-upload>
                        </ng-container>
                        <ng-template #imgViewRef>
                          <div
                            class="tenant-logo d-flex align-items-center m-0"
                            [ngClass]="{
                              'tenant-default-logo':
                                propertyName | call : isDefaultLogo,
                              'tenant-default-banner':
                                propertyName | call : isDefaultBanner
                            }"
                          >
                            <a appUnsavedFormCheck [href]="windowOrigin">
                              <img
                                [src]="
                                  getFormControlByPropertyName(propertyName)
                                    ?.value?.url ||
                                  getFormFieldValueFromEntity(propertyName)?.url
                                "
                                appDefaultPlaceholder
                                appDynamicImgUrl
                              />
                            </a>
                          </div>
                        </ng-template>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      <app-form-field-custom
                        [formInput]="form"
                        [showLabel]="!excludeLabelFields.includes(propertyName)"
                        [editable]="
                          canEdit(
                            propertyName,
                            sectionTenantAttributes.editting
                          )
                        "
                        [type]="
                          getAttributeTypeByPropertyName(propertyName) ===
                          AttributeType.String
                            ? AttributeType.StringSingle
                            : null
                        "
                        [attributeDescription]="getFieldOptions(propertyName)"
                      >
                      </app-form-field-custom>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </app-form-section>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <ngb-accordion
      [activeIds]="['tenant-email-configurations']"
      [destroyOnHide]="false"
    >
      <ngb-panel
        cardClass="venture__body venture__accordion ignore-overflow"
        id="tenant-email-configurations"
      >
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            class="btn d-flex align-items-center justify-content-between w-100 text-left"
            ngbPanelToggle
            type="button"
          >
            <div translate class="pull-left float-left">
              <span
                class="venture__company-header mr-5 d-block d-lg-inline-block"
                >Tenant Mail Configurations</span
              >
            </div>
            <i
              class="fas float-right pull-right venture__company-header"
              [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
            ></i>
          </button>
        </ng-template>

        <ng-template ngbPanelContent>
          <div
            class="venture__body widget__card--background m-0"
            *ngIf="form && formSections.length > 0"
          >
            <app-form-section
              #sectionTenantEmailConfig
              [index]="TenantConfigurationSectionIndex.TenantEmailConfiguration"
              [canSave]="
                isValidSection(
                  TenantConfigurationSectionIndex.TenantEmailConfiguration
                )
              "
              [showEditButton]="!sectionTenantEmailConfig.editting"
              [saveSectionCallback]="saveCustomAttributeSection.bind(this)"
              (cancelSection)="cancelSection($event)"
              (editIndexChange)="markEditingSectionIndex($event)"
              [preSaveSectionCallback]="preSaveSection.bind(this)"
            >
              <ng-container
                *ngFor="
                  let propertyName of formSections[
                    TenantConfigurationSectionIndex.TenantEmailConfiguration
                  ].fieldsName;
                  index as fieldIndex
                "
              >
                <div class="tenant-form-field" [class.mt-0]="fieldIndex === 0">
                  <ng-container
                    [ngSwitch]="getAttributeTypeByPropertyName(propertyName)"
                  >
                    <ng-container *ngSwitchDefault>
                      <app-form-field-custom
                        [formInput]="form"
                        [showLabel]="!excludeLabelFields.includes(propertyName)"
                        [editable]="
                          canEdit(
                            propertyName,
                            sectionTenantEmailConfig.editting
                          )
                        "
                        [type]="
                          propertyName | call : getAttributeTypeByPropName
                        "
                        [attributeDescription]="getFieldOptions(propertyName)"
                        [options]="
                          propertyName | call : getMailConfigFieldOptions
                        "
                        (storedValue)="onStoreValueChange($event)"
                      >
                      </app-form-field-custom>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </app-form-section>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</ng-container>
