import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CropperComponent } from 'angular-cropperjs';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import {
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageTransform,
  LoadedImage,
} from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { SuperImageCropper } from 'super-image-cropper';
@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageInputComponent implements AfterViewInit {
  @Input() aspectRatio: number;

  @ViewChild(ImageCropperComponent) imgCropper: ImageCropperComponent;

  @Output() loadImgFailed: EventEmitter<void> = new EventEmitter();

  acceptType = '*';

  scale = 1;

  transform: ImageTransform = {};

  imageFile: File;

  originalImageFile: LoadedImage;

  imageUrl = '';
  croppedImgUrl = '';
  cropperOptions = {
    aspectRatio: 1,
    zoomOnWheel: false,
  };

  @ViewChild('angularCropper') public angularCropper: CropperComponent;

  constructor(public activeModal: NgbActiveModal) {}

  ngAfterViewInit() {
    this.cropperOptions.aspectRatio = this.aspectRatio;
  }

  dropped(files: NgxFileDropEntry[]): void {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          this.imageFile = file;

          this.imageUrl = URL.createObjectURL(file);
        });
      }
    }
  }

  loadImageFailed(): void {
    this.clearImg();
    this.loadImgFailed.emit();
  }

  crop(): void {
    const isGif = this.isGIF(this.imageFile);
    if (!isGif) {
      this.angularCropper.cropper.getCroppedCanvas().toBlob((blob) => {
        this.imageCropped(null, blob);
      });
    } else {
      this.cropGifImage();
    }
  }

  onZoom(event: string): void {
    this.transform = {
      ...this.transform,
      scale: Number(event),
    };

    this.angularCropper.cropper.scale(Number(event));
    console.log({ scale: Number(event) });
  }

  imageCropped(event: ImageCroppedEvent, blob?: any): void {
    let file: File;
    if (event) {
      const { width, height } = event;
      const { original } = this.originalImageFile;
      const isCropped = !(
        width === original.size.width && height === original.size.height
      );
      const isScaled =
        'scale' in this.transform ? this.transform.scale !== 1 : false;

      if (!isCropped && !isScaled) {
        file = new File([this.imageFile], this.imageFile.name);
      } else {
        file = new File([event.blob], this.imageFile.name);
      }
    }

    if (blob) {
      file = new File([blob], this.imageFile.name);
    }

    console.log({ file, blob: blob ? blob : event.blob });
    this.clearImg();
    this.activeModal.close(file);
  }

  clearImg(): void {
    this.imageFile = null;
  }

  imageLoaded(img: LoadedImage): void {
    this.originalImageFile = img;
  }

  isGIF(file) {
    // Check if the file type is a GIF
    if (file.type.startsWith('image/gif')) {
      return true;
    }

    // If not, try to determine the file type based on the file extension
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return fileExtension === 'gif';
  }

  cropGifImage() {
    const imageCropper = new SuperImageCropper();

    imageCropper
      .crop({
        cropperInstance: this.angularCropper.cropper,
        src: this.imageUrl,
        outputType: 'blob',
      })
      .then((blob) => {
        this.imageCropped(null, blob);
        this.croppedImgUrl = blob.toString();
      });
  }
}
